
.exam-page {
    display: grid;
    grid-template-columns: 3fr 0fr;
    gap: 20px;
    padding: 20px;
    /* height: 65vh; */
  }
  
  .question-section {
    background: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
  }
  
  .options label {
    display: block;
    margin: 8px 0;
  }
  
  .navigation-panel {
    background: #e6f7ff;
    padding: 20px;
    width: 25%;
  }
  
  .question-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
  
  .question-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
  
  .question-button.answered {
    background-color: #ff7200;
    color: white;
  }
  
  .question-button.marked {
    color: #ff7200;
    background-color: white;
  }
  
  .question-button.not_visited {
    background-color: gray ;
    color: #fff;
  }

  .question-button.not_answered {
    background-color: lightcyan ;
    color: #000;
  }
  
  /* Footer style to fix the buttons at the bottom */
.controls-footer.fullscreen {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 75%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #f9f9f9;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  .controls-footer {
    position: fixed;
    bottom: 0;
    width: 75%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #f9f9f9;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Left and right sections for button grouping */
  .left-controls {
    display: flex;
    gap: 10px;
  }
  
  .right-controls {
    margin-left: auto;
  }
  
  /* Style for "Clear Response" button */
  .clear-response-btn {
    background-color: #f2f2f2;
    color:  #000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .clear-response-btn:hover {
    background-color: #ff7200;
    color: white;
  }
  /* Style for "Mark for Review & Next" button */
  .mark-for-review-btn {
    background-color: #f2f2f2;
    color:  #000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .mark-for-review-btn:hover {
    background-color:#ff7200;
    color: white;
  }
  
  /* Style for "Save & Next" button */
  .save-next-btn {
    background-color: #f2f2f2;
    color:  #000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .save-next-btn:hover {
    background-color:#ff7200;
    color: white;
  }
  
  /* Arrow icon for "Save & Next" button */
  .arrow {
    font-size: 1.2em;
  }
  
  /* Style for "Submit Test" button */
  .submit-btn.fullscreen {
    background-color:#ff7200;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;
    text-align: center;
    position: fixed;
    bottom: 15px;
    right: 15px;
  }

  .submit-btn {
    background-color:#ff7200;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 40px;
  }

  

  .legend {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .legend-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upper-legend {
    display: flex;
    justify-content: space-evenly;
    width: 100%; /* Adjust this width as needed */
    margin-bottom: 10px; /* Space between upper and lower items */
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 15px;
  }
  
  .bottom-legend {
    text-align: center;
  }
  
  
  .legend-color {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: 3px;
  }
  
  /* Status colors */
  .not-visited {
    background-color: gray ;
    color: #000;
  }
  
  .answered {
    background-color: #ff7200;
    color: white;
  }
  
  .marked {
    color: #ff7200;
    background-color: white;
    }
  
  /* Question.css */

.question-card {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    position: relative;
  }
  
  .question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .question-number {
    color: #555;
    background-color: #fcecd7;
    width: 100px;
    text-align: center;
  }

  
  .question-text {
    font-size: 22px;
    font-weight: 700;
    margin-top: 20px;
    line-height: 1.4;
    overflow-y: auto;  
    max-height: 250px;  /* Limit the height of the question text */
  }
  
  .question-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    overflow-y: auto;  
  }
  
  .option {
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  label.option span:not(.option-label){
    display: flex!important;
    align-items: center;
    gap:10px;
  }
  
  .option input[type="radio"] {
    margin-right: 10px;
    accent-color: #ff8800;
  }
  
  .option-label {
    color: #ff8800;
  }
  
  .next-button {
    background-color: #cfcfcf;
    color: #666;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .next-button:hover {
    background-color: #bbb;
  }
  
  .exam-header {
    background-color: #0dbce878;
    padding: 20px;
    text-align: center;
    border-bottom: 2px solid #ccc;
  }
  
  .exam-header h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }

  /* New Section Below Header */
.exam-info-section {
  display: flex;
  justify-content: space-between; /* Aligns items at each end */
  align-items: center;
  background-color: #f9f9f9;
  padding: 15px 20px;
  border: 1px solid #ddd;
}

.info-item {
  font-size: large;
}

.question-number {
  font-size: 16px;
  color: #333;
}

.exam-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Aligns controls (Marks, Type, Report) to the right */
}

.marks-section {
  display: flex;
  align-items: flex-start;
}

.marks-buttons {
  display: flex;
  gap: 10px;
}

.positive-mark-btn,
.negative-mark-btn {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  width: 50px;
  text-align: center;
  margin-left: 10px;
}

.positive-mark-btn {
  background-color: #4caf50; /* Green */
  color: white;
}

.negative-mark-btn {
  background-color: #f44336; /* Red */
  color: white;
}


/* Timer Section Styling */
.timer {
  background-color: #ff7200;  /* Orange background */
  color: white;  /* White text */
  font-size: 20px;  /* Font size */
  font-weight: bold;  /* Bold text */
  padding: 10px 20px;  /* Padding around text */
  border-radius: 8px;  /* Rounded corners */
  margin-top: 20px;  /* Margin to separate from content */
  text-align: center;  /* Centered text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  /* Shadow for depth */
  max-width: 200px;  /* Limiting the width */
  margin: 20px auto;  /* Center horizontally */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-container h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.modal-container p {
  font-size: 1rem;
  margin-bottom: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.submit-cancel-btn, .submit-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-cancel-btn {
  color: #ff7200;
  background-color: white;
  height: 45px;
  border: solid 1px lightgray;
  align-self: flex-end;
}

.submit-btn {
  background-color: #ff7200;
  color: white;
}

.submit-cancel-btn:hover, .submit-btn:hover {
  opacity: 0.8;
}
