*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@font-face{
    src:url(../font/DroidSerif-Regular.ttf);
    font-family: fontdesign;
}
a{
    text-decoration: none!important;
}
body{
    /* background-color: #FAF6F3!important; */
    background-color: #f3f4fa!important;
    color: #161414!important;
    font-weight: 400!important;
    font-family: fontdesign!important;
    font-size: .8em;
}
.hero_section{
    padding: 4rem 3.5rem;
}
.hero_title_box .hero_title{
    color: #161414;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0.86px;
    text-transform: capitalize;
    margin-top: 4rem;
}
.hero_title_box .hero_title span{
    color: #FD5932;
}
.hero_title_box p{
    color: #000;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0.42px;
}
.hero_img img{
    width: 100%;
}
.explore_btn{
    border-radius: 8px;
    background: #FD5932;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.07);
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 0.44px;
    text-transform: uppercase;
    padding: 1rem 2rem;
}

.explore_btn i{
    transform: rotate(-38deg);
    flex-shrink: 0;
    font-size: 26px;
}



/*first-module*/
.first_module{
    padding: 4rem 3.5rem;
}
.first_module_title{
    color: #161414;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 121%;
}
.first_module_subtitle{
    color: #383838;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.first_module .sub_module1{
    border-radius: 20px;
    box-shadow: 0px 4px 4px 9px rgba(253, 89, 50, 0.02);
    padding: 2rem;
}
.module1{
    background: #FFF1F1;
}
.module2{
    background: #FFEAFC;
}
.module3{
    background: #F9F5FF;
}
.module4{
    background: #F2F7FF;
}
.module5{
    background: #F6FFED;
}
.module6{
    background: #FFEFE6;
}
.module1 .icon_box img{
    background: #FFDDDD;
}
.module2 .icon_box img{
    background: #FFB6F5;
}
.module3 .icon_box img{
    background: #EADBFF;
}
.module4 .icon_box img{
    background: #CBDFFF;
}
.module5 .icon_box img{
    background: #E3FFC7;
}
.module6 .icon_box img{
    background: #FFE1D0;
}
.first_module .sub_module1 .question_bank_icon img{
    border-radius: 8px;
    width: 85px;
    height: 85px;
    padding: 1rem;
    margin: auto;
    display: block;
}
.sub_module1{
    margin: 2rem 0rem 0rem;
}
.sub_module1 .icon_box{
    padding: 1rem;
}
.sub_module1 .submodule1_content{
    padding: 1rem 1rem;
}

.sub_module1 .submodule1_content h3{
    color: #161414;;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}
.sub_module1 .submodule1_content p{
    color: #585858;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/*feature*/
.feature1, .feature2, .feature3{
    border-radius: 10px;
    background: #FFFAF2;
    box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.10);
    padding: 1rem 2rem;
    height: 100%;
    margin: 2rem 0rem 0rem;
}
.feature1 .icon_box{
    padding: 1rem 0rem;
}
.feature1 .icon_box img{
    border-radius: 5px;
    background: #FD5932;
    width: 60px;
    height: 60px;
    padding: 0.5rem;
    box-shadow: rgba(255, 114, 0, 1) 2px 1px 23px 2px;
}
.feature1 .feature1_content h3{
    color: #FD5932;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 126.688%;
    padding: 0.6rem 0rem 0.3rem
}
.feature1 .feature1_content p{
    color: #585858;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

/*career insight*/
.career_section{
    background: #FDFCF5;
}
.box1{
    border-radius: 14px 0px 0px 14px;
    background: #FFF;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.09);
    padding: 1.5rem 2rem;
    margin: 1rem 0rem;
    width: 100%;
}
.box1 .icon_box img{
    border-radius: 5px;
    width: 60px;
    height: 60px;
    padding: 0.5rem;
}
.box1 .zig_zag_content h3{
    color: #161414;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.zig_zag_content{
    padding: 1.5rem 0rem 0.5rem;
}
.box1 .zig_zag_content p{
    color: #585858;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.zig_zag_box .design_box1{
    border-left: 6px solid #C7FA74;
}
.zig_zag_box .design_box1 .icon_box img{
    background: #C7FA74;
    box-shadow: rgba(199, 250, 116, 1) 2px 1px 23px 2px;
}
.zig_zag_box .design_box2{
    border-left: 6px solid #FAC474;
}
.zig_zag_box .design_box2 .icon_box img{
    background: #FAC474;
    box-shadow: rgba(250, 196, 116, 1) 2px 1px 23px 2px;
}
.zig_zag_box .design_box3{
    border-left: 6px solid #B774FA;
}
.zig_zag_box .design_box3 .icon_box img{
    background: #B774FA;
    box-shadow: rgba(183, 116, 250, 1) 2px 1px 23px 2px;
}
.zig_zag_box .design_box4{
    border-left: 6px solid #FA74A4;
    margin-left: 4rem;
}
.zig_zag_box .design_box4 .icon_box img{
    background: #FA74A4;
    box-shadow: rgba(250, 116, 164, 1) 2px 1px 23px 2px;
}
.zig_zag_box .design_box5{
    border-left: 6px solid #7491FA;
}
.zig_zag_box .design_box5 .icon_box img{
    background: #7491FA;
    box-shadow: rgba(116, 145, 250, 1) 2px 1px 23px 2px;
}
.first_module .career_content h2{
    color: #161414;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 121%;
}
.first_module .career_content p{
    color: #585858;
    font-size: 21px;
    font-family: Droid Serif;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}                                                            


/*networking boost*/
.boost{
    border-radius: 19px;
    background: #FFF6E5;
    padding: 4rem 4rem;
}
.performance img, .boost_img img{
    width: 100%;
}
.performance img{
    margin-top: 11rem;
}
.boost_content h2{
    color: #161414;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.boost_content p{
    color: #000;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
}

/*knowledge hub*/
.knowledge_hub{
    background: #FDFCF5;
}
.knowledge_box1{
    border-radius: 14px;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.09);
    padding: 1.5rem 2rem;
    margin: 1rem 0rem;
}
.knowledge_box1 .icon_box img{
    border-radius: 5px;
    width: 60px;
    height: 60px;
    padding: 0.5rem;
}
.knowledge_box1 .knowledge_box_content{
    padding: 1rem 0rem;
}
.knowledge_box1 .knowledge_box_content h3{
    color: #161414;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}
.knowledge_box1 .knowledge_box_content p{
    color: #585858;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
}
.hub1{
    border: 0px solid #FAC474;
    background: #F6FFD3;
}
.hub1 .icon_box img{
    background: #FAC474;
    box-shadow: rgba(250, 196, 116, 1) 2px 1px 23px 2px;
}
.hub2{
    border: 0px solid #B774FA;
    background: #FFE3F5;
}
.hub2 .icon_box img{
    background: #B774FA;
    box-shadow: rgba(183, 116, 250, 1) 2px 1px 23px 2px;
}
.hub3{
    border: 0px solid #7491FA;
    background: #FFF0E5;
}
.hub3 .icon_box img{
    background: #7491FA;
    box-shadow: rgba(116, 145, 250, 1) 2px 1px 23px 2px;
}
.knowledge_content_box{
    padding-left: 1rem;
}
.knowledge_content_box h2{
    color: #161414;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 121%;
}
.knowledge_content_box p{
    color: #585858;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
/*showtalent video*/
.boxing1{
     border-radius: 14px;
     padding: 1.5rem;
     margin-top: 0.5rem;
     height: 100%;
}
.video_content{
    padding: 1rem 0rem;
}
.boxing1 .video_content h3{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}
.boxing1 .video_content p{
    color: #585858;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.boxing1 .icon_box img{
    border-radius: 5px;
    background: #FD5932;
    width: 60px;
    height: 60px;
    padding: 0.5rem;
    box-shadow: rgba(255, 114, 0, 1) 2px 1px 23px 2px;
}
.video1{
    background: #FDEEFF;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.09);
}
.video2{
    background: #EEFAFF;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.09);
}
.video3{
  background: #FEFFEE;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.09);
}

/*resource library*/
.resource_library{
    background: rgba(217, 217, 217, 19%);
}
.first_box{
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.09);
    padding: 1.5rem;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    height: 100%;
}
.resource_box1{
    border-right: 14px solid #B774FA;
    border-bottom: 14px solid #B774FA;
}
.first_box .icon_box img{
    width: 65px;
    height: 65px;
    border-radius: 5px;
    background: #B774FA;
    padding: 0.8rem;
}
.resource_box1 .icon_box img{
    background: #B774FA;
    box-shadow: rgba(183, 116, 250, 1) 2px 1px 23px;
}
.first_box .box_content{
    padding-left: 1.5rem;
}
.first_box .box_content h3{
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}
.first_box .box_content p{
    color: #585858;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.resource_box2 .icon_box img{
    background: #FF5771;
    box-shadow: rgba(255, 87, 113, 1) 2px 1px 23px;
}
.resource_box2{
    border-right: 14px solid #FF5771;
    border-bottom: 14px solid #FF5771;
}
.resource_box3{
    border-right: 14px solid #7491FA;
    border-bottom: 14px solid #7491FA;
}
.resource_box3 .icon_box img{
    background: #7491FA;
    box-shadow: rgba(116, 145, 250, 1) 2px 1px 23px;
}

/*personal networking*/
.personal_networking{
    padding: 3rem 0rem;
}
.networking{
    background: url('../icon/bg_design.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}
.network_box{
    border-radius: 23px;
    background: #FFF;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 3rem 2rem;
    margin-top: 2rem;
}
.network_box .icon_box img{
    width: 70px;
    height: 70px;
    border-radius: 12px;
    padding: 0.8rem;
}
.network_box .box_content{
    padding: 1rem 0rem;
}
.network_box .box_content h3{
    color: #161414;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.network_box .box_content p{
    color: #383838;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.personal_box1 .icon_box img{
    background: rgba(255, 243, 217, 0.67);
}
.personal_box2 .icon_box img{
    background: #FFEBEB;
}
.personal_box3 .icon_box img{
    background: #EBF6E7;
}

/*educational networking*/
.educational_network{
    background: rgba(217, 217, 217, 19%);
}
.educational_box{
    border-radius: 14px;
    border: 0px solid #C7FA74;
    background: #FFF;
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.09);
    padding: 2rem;
    margin: 1rem 0rem;
}
.educational_network_content h2{
    color: #161414;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 121%;
}
.educational_network_content p{
    color: #585858;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}
.educational_box .icon_box img{
    width: 65px;
    height: 65px;
    border-radius: 5px;
    padding: 0.8rem;
}
.educational_network_box1 .icon_box img{
    background: #C7FA74;
    box-shadow: rgba(199, 250, 116, 1) 2px 1px 23px;
}
.educational_box .education_content{
    padding: 1.5rem 0rem 1rem;
}
.educational_box .education_content h3{
    color: #161414;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}
.educational_box .education_content p{
    color: #585858;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.educational_network_box2 .icon_box img{
    background: #B774FA;
    box-shadow: rgba(183, 116, 250, 1) 2px 1px 23px;
}
.educational_network_box3 .icon_box img{
    background: #FAC474;
    box-shadow: rgba(250, 196, 116, 1) 2px 1px 23px;
}
.educational_network_box4 .icon_box img{
    background: #7491FA;
    box-shadow: rgba(116, 145, 250, 1) 2px 1px 23px;
}

/*network building*/
.networking_building{
    background: url('../icon/right_bg_design.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.building_network{
    position: relative;
    overflow: hidden;
}
.networking_building_content h2{
    color: #161414;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;   
}
.networking_building_content p{
    color: #383838;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
    margin: 0;
}
.network_building_box1 .icon_box img{
    background: #D2E7FF; 
    margin: auto;
    display: block;   
}
.network_building_box2 .icon_box img{
    background: rgba(255, 243, 217, 0.67);
    margin: auto;
    display: block;
}
.network_building_box3 .icon_box img{
    background: #FFEBEB;
    margin: auto;
    display: block;
}
.network_building_box4 .icon_box img{
    background: rgba(235, 246, 231, 1);
    margin: auto;
    display: block;
}
.network_building_box1{
    margin-top: 3rem;
}
.network_building_box2{
    margin-top: -3rem;
}
.network_building_box3{
    margin-top: 2rem;
}
.network_building_box4{
    margin-top: -2rem;
    background: #FFFAF2;
}
.network_building_box1 h3, .network_building_box1 p,
.network_building_box2 h3, .network_building_box2 p,
.network_building_box3 h3, .network_building_box3 p,
.network_building_box4 h3, .network_building_box4 p{
    text-align: center;
}

/*community interaction*/
.community_network{
    background: #f5f5f5;
    margin: 3rem 0rem;
}
.comunity_graphic img{
    width: 100%;
/*    padding: 2rem*/
}
.community_network_content h2{
    color: #161414;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
}
.community_network_content p{
    color: #585858;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}
.community_box{
    display: flex;
    align-items: center;
    padding: 2rem;
    border-radius: 11px;
    background: #FFF;
    box-shadow: 2px 2px 2px 10px rgba(253, 89, 50, 0.03);
    margin-top: 2rem;

}
.community_box .community_box_content{
    padding-left: 2rem;
    border-left: 3px solid #B774FA;
}
.community_box .icon_box img{
    border-radius: 5px;
    background: #B774FA;
    box-shadow: rgba(183, 116, 250, 1) 2px 1px 23px;
    margin-right: 2rem;
}
.community_box_content h3{
    color: #161414;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}
.community_box_content p{
   color: #585858;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}
/*collaboration opportunities*/
.collabration_img img{
    width: 100%;
}
.collabration_content h2{
    color: #161414;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
}
.collabration_content p{
    color: #585858;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}
.collab_box{
    border-radius: 14px;
    border: 0px solid #FAC474;    
    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.09);
    padding: 1.8rem;
    margin-top: 2rem;
    height: 100%;
}
.collaboration_box1{
    background: #EEFAFF;   
}
.collaboration_box1 .icon_box img{
    border-radius: 12px;
    background:#D2E7FF;
    padding: 0.8rem;
    width: 65px;
    height: 65px;
}
.collaboration_box2 .icon_box img{
    border-radius: 12px;
    background:#F1D1FC;
    padding: 0.8rem;
    width: 65px;
    height: 65px;
}
.collaboration_box2{
    background: #F9EFFF;
}
.collab_box .collab_content{
    margin-top: 2rem;
}
.collab_box .collab_content h3{
    color: #161414;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}
.collab_box .collab_content p{
    color: #585858;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
/*join community*/
.right_community_member img, .left_community_member img{
    width: 100%;
} 
.join_community_box{
    padding: 3rem;
    border-radius: 26px;
    background: radial-gradient(505.54% 58.12% at 50.04% 50.13%, rgba(222, 226, 250, 0.00) 0%, #DEE2FA 100%);
}
.center_community_content h2{
    color: #161414;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.center_community_content p{
    color: #383838;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.subscribe_newsletter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 2rem auto;
}
.subscribe_newsletter{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.07);
}
.subscribe_newsletter input{
    border: none;
    padding: 1rem;
    box-shadow: none;
    outline: none;
    width: 70%;
}
.subscribe_newsletter input:hover, .subscribe_newsletter input:focus, .subscribe_btn:hover, .subscribe_btn:focus{
    box-shadow: none!important;
    border: none!important;
    outline: none!important;
}
.subscribe_newsletter .subscribe_btn{
    width: 30%;
    padding: 1rem;
    border-radius: 0px 8px 8px 0px!important;
    background: #FD5932!important;
    border: none!important;
}


/*hover effect*/
.sub_module1 .submodule1_content h3:hover, .box1 .zig_zag_content h3:hover, .knowledge_box1 .knowledge_box_content h3:hover, .boxing1 .video_content h3:hover, .first_box .box_content h3:hover, .network_box .box_content h3:hover, .educational_box .education_content h3:hover, .network_box .box_content h3:hover, .community_box_content h3:hover, .collab_box .collab_content h3:hover{
    color: #ff7200;
    transition: all 0.6s ease-in-out;
}
.module1 .icon_box img:hover{
    box-shadow: rgba(255, 102, 102, 1) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.module2 .icon_box img:hover{
    box-shadow: rgba(255, 31, 224, 1) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.module3 .icon_box img:hover{
    box-shadow: rgba(155, 87, 250, 1) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.module4 .icon_box img:hover{
    box-shadow: rgba(52, 127, 249, 1) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.module5 .icon_box img:hover{
    box-shadow: rgba(155, 255, 55, 1) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.module6 .icon_box img:hover{
    box-shadow: rgba(255, 125, 52, 1) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}



.zig_zag_box .design_box1 .icon_box img:hover{
    box-shadow: rgb(171 255 35) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;

}
.zig_zag_box .design_box2 .icon_box img:hover{
    box-shadow: rgb(255 169 41) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;

}
.zig_zag_box .design_box3 .icon_box img:hover{
    box-shadow: rgb(155 56 255) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;

}
.zig_zag_box .design_box4 .icon_box img:hover{
    box-shadow: rgb(255 44 120) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;

}
.zig_zag_box .design_box5 .icon_box img:hover{
    box-shadow: rgb(61 102 250) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;

}

.hub1 .icon_box img:hover{
    box-shadow: rgb(255 169 41) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}
.hub2 .icon_box img:hover{
    box-shadow: rgb(155 56 255) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}
.hub3 .icon_box img:hover{
    box-shadow: rgb(61 102 250) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}
.boxing1 .icon_box img:hover{
    box-shadow: rgba(255, 114, 0, 1) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}

.resource_box1 .icon_box img:hover{
    box-shadow: rgb(155 56 255) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}
.resource_box2 .icon_box img:hover{
    box-shadow: rgb(255 23 59) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}
.resource_box3 .icon_box img:hover{
    box-shadow: rgb(61 102 250) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}
.personal_box1 .icon_box img:hover{
    box-shadow: rgb(255 174 0) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.personal_box2 .icon_box img:hover{
    box-shadow: rgb(255 45 45) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.personal_box3 .icon_box img:hover{
    box-shadow: rgb(100 255 44) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.educational_network_box1 .icon_box img:hover{
    box-shadow: rgb(171 255 35) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;   
}
.educational_network_box2 .icon_box img:hover{
     box-shadow: rgb(155 56 255) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease; 
}
.educational_network_box3 .icon_box img:hover{
    box-shadow: rgb(255 169 41) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}
.educational_network_box4 .icon_box img:hover{
    box-shadow: rgb(61 102 250) 2px 1px 23px 2px;
    transform:scale(1.2);
    transition: all 0.5s ease;
}
.network_building_box1 .icon_box img:hover{
    box-shadow: rgb(49 145 255) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.network_building_box2 .icon_box img:hover{
    box-shadow: rgb(255 181 20) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.network_building_box3 .icon_box img:hover{
    box-shadow: rgb(255 0 0) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.network_building_box4 .icon_box img:hover{
    box-shadow: rgb(87 252 27) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.community_box .icon_box img:hover{
    box-shadow: rgb(155 56 255) 2px 1px 23px 2px;
    transform: scale(1.2);
    transition: all 0.5s ease;
}
.collaboration_box1 .icon_box img:hover{
    box-shadow: rgb(49 145 255) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.collaboration_box2 .icon_box img:hover{
    box-shadow: rgb(200 41 255) 2px 1px 23px 2px;
    transition: all 0.5s ease;
}
.explore_btn:hover{
    color:#ff7200;
    background: #fff;
    border: 1px solid #ff7200;
    box-shadow: rgba(255, 114, 0, 1) 0px 0px 14px 0px;
    transition: all 0.6s ease;
}

/* footer styling START  */
footer {
    line-height: 1.9!important;
    /* background: radial-gradient(circle, rgba(17, 36, 0, 0) 0%, rgb(251 234 204 / 58%) 55%, rgba(255, 201, 0, 0.11808473389355745) 100%)!important; */
    /* background: white; */
    margin: 0px 0px;
    border-radius: 10px;
    padding: 0px 50px!important;
}
.footer-bg {
    background-position: center bottom;
}
.top-footer-part {
    background: white;
    padding: 2rem;
    box-shadow: 0px 0px 6px 2px rgb(51 51 51 / 6%);
    border-radius: 8px;
}

  .copyright{
    color: #585858;
  }
  footer {
    line-height:1.9!important;
    margin: 0px 0px;
    border-radius: 10px;
    padding: 0px 10px!important;
}
          .footer-listing.left-arrow a:before {
            content: '.';
            font-family: monospace;
            margin-right: 4px;
            font-size: 30px;
            line-height: 0.5;
            display: flex;
            justify-content: flex-start;
            margin-left: -5px;
            /* color: #c3c3c3; */
            }

            .copyright {
              font-size: 0.8rem;
            }

            .top-footer-part {
              background: white;
              padding: 2rem;
              box-shadow: 0px 0px 6px 2px rgb(51 51 51 / 6%);
              border-radius: 8px;
            }

            @media  screen and (max-width:767px) {

footer {
  padding: 0px 15px !important;
}

.dvo,
.gadsfooter {
  display: none;
}

.footer-cols .col:nth-child(4) {
  margin-top: -0rem !important;
}

#secure-content {
  margin-bottom: 70px !important;
}
  .footer-listing a {   
      font-size: 10.8px!important;
     padding:0!important;
     word-break: break-word!important;
     display:flex!important;
  }
  .footer-listing.left-arrow a:before {
    content: '.'!important;
    font-family: monospace;
    margin-right: 3px!important;
      line-height: 0.2!important;
  }
  }

label.text-uppercase {
font-weight: 500;
font-size: 0.9rem;
}

.footer-listing a {
  outline: 0;
  width: 100%;
  float: left;
  padding: 2px 0px;
  font-size: 15px;
  color: #6b6b6b;
  display: flex;
  word-break: break-all;
}

.footer-ul-0 a {
outline: 0;
padding: 0 14px;
color: #333;
font-weight: 600;
font-size: 0.8rem;
}

.footer-listing a:hover {
color: #ff7200 !important;
}

.secure-content {
position: relative;
z-index: 1;
}

.secure-content img {
margin-top: -6.3rem !important;
}
      
	  .top-footer-part {
    background: white;
    padding: 2rem;
    box-shadow: 0px 0px 6px 2px rgb(51 51 51 / 6%);
    border-radius: 8px;
}

.footer-ul-0 a {
    outline: 0;
    padding: 0 14px;
    color: #333;
    font-weight: 600;
    font-size: 0.8rem;
}

label.text-uppercase {
    font-weight: 500;
    font-size: 0.9rem;
}
.flex-3{
    flex:3;
}

.footer-listing a {
    outline: 0;
    width: 100%;
    float: left;
    padding: 2px 0px;
    font-size: 15px;
    color: #6b6b6b;
    display: flex;
    word-break: break-all;
}

.footer-listing.left-arrow a:before {
    content: '.';
    font-family: monospace;
    margin-right: 4px;
    font-size: 30px;
    line-height: 0.5;
    display: flex;
    justify-content: flex-start;
    margin-left: -5px;
}
@media screen and (min-width: 768px){
    #secure-content {
        margin-top: -40px;
    }
    .mobile-view-only, .mobile-view-only-1 {
        display: none;
    }
.desktop-view-only, .desktop-view-only-1 {
    display: inline-block;
}
}
@media screen and (max-width: 767px){
    #secure-content {
        text-align: center;
        margin:0 auto;
        width: 100%;
    }
    .more-menu{
        left: 0;
        right: 0;
        top: 60px;
        bottom: 0;
        transform: none;
    }

    .mobile-view-only, .mobile-view-only-1 {
        display: block;
    }
.desktop-view-only, .desktop-view-only-1 {
    display: none;
}
}
.label-success{
    color: #4d971a!important;
      padding: 0px 11px;
      border-radius: 10px;
  }
  .label-danger{
    color: #af1a38;
      padding: 0px 11px;
      border-radius: 10px;
  }
  .notFoundImg{
    /* width: 90px; */
    opacity: 0.6;
    margin: 10px auto;
  }
  .failed-label{
    font-size: 1rem;
    background: #ffd9d9;
    padding: 3px 10px;
    border-radius: 40px;
}
.success-label{
    font-size: 1rem;
    background: #dbffd9;
    padding: 3px 10px;
    border-radius: 40px;
}
.bg-theme{
    background:#ff7200;
}
.bg-theme:hover{
    background:#ec6b03;
}
.choosen-plan-check{
    position: absolute;
    z-index: 1;
    background-color: rgb(0 0 0 / 22%);
    left: 12px;
    right: 12px;
    top: 0;
    bottom: 16px;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}
.circle-leader-board-1{
    /* overflow: hidden; */
    width:130px;
    height:130px;
    border-radius: 130px;
    border:6px solid #ffc329;
    margin:3rem auto;
}

.circle-leader-board-2{
    /* overflow: hidden; */
    width:100px;
    height:100px;
    border-radius: 100px;
    border:6px solid #73d0e0;
    margin:3rem auto;
}

.circle-leader-board-3{
    /* overflow: hidden; */
    width:100px;
    height:100px;
    border-radius: 100px;
    border:6px solid #f95e7a;
    margin:3rem auto;
}
.top-leader-board-container{
    position: sticky;
    top: 70px;
    padding-bottom: 3rem;
}
.crown{
    margin: 0 auto;
    margin-top: -48px;
}
.wing-right{
    margin-top: 1rem;
    margin-right: -3rem;
}
.wing-left{
    margin-top: 1rem;
    margin-left: -3rem;
    direction:rtl
}

.scoretab{
    position: absolute;
    bottom: -22px;
    left: 0;
    right: 0;
    padding:3px 15px;
    border-radius: 50px;
    color: white;
    z-index: 2;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.score-first{
    
    background: #f3b923;
    border: 4px solid #ffc329;
}

.score-second{    
    background: #03b5d6;
    border: 4px solid #73d0e0;
}
.score-third{
    background: #b90e0e;
    border: 4px solid #f95e7a; 
}
.leaderboard-img-container{
   
    overflow: hidden;
    position: absolute;
    top: 0px;
}
.leaderboard-1{
    width: 119px;
    height: 119px;
    border-radius: 100px;
}
.leaderboard-2{
    width: 89px;
    height: 87px;
    border-radius: 100px;
}
.leaderboardUsr{
    width: auto;
    max-width: fit-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.leaderboardUsr.leader-1{    
    height: 130px;
}
.leaderboardUsr.leader-2{    
    height: 86px;
}
.leaderboardUsr.leader-3{    
    height: 86px;
}
.second-txt{
    position: absolute;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 4px #73d0e0;
    z-index: 2;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: -3rem;
    transform: rotate(-22deg);
}
.third-txt{
    position: absolute;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 4px #f95e7a;
    z-index: 2;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: -3rem;
    transform: rotate(-22deg);
}
@keyframes fadeIn {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.fade-in-message {
    display: inline-block;
    color:#7f73f3;
    border-radius: 5px;
    animation: fadeIn 1s ease-out;
    position:absolute;
}
.recommended-tag{
    color: wheat;
    background: #166054;
    padding: 0px 27px;
    transform: rotate(45deg);
    transform-origin: center;
    position: absolute;
    right: -29px;
    top: 25px;
    font-size: 0.6rem;
}

.popular-tag{
    color: wheat;
    background: #ff0000;
    padding: 0px 27px;
    transform: rotate(45deg);
    transform-origin: center;
    position: absolute;
    right: -27px;
    top: 11px;
    font-size: 0.6rem;
}
.group-title{
    font-size: .8rem;
    color:#ccc;
    padding-left:5px;
    margin-top:10px;
    margin-bottom: 5px;
}
.credit-btn{
    background: linear-gradient(#ff7200,#ffb500);
    color: white;
    gap: 10px;
    padding: 0.6rem 0;
}
.react-tel-input .country-list .search{
    margin-right: 5px;
}
.react-tel-input .country-list .search-box{
    height: 34px!important;
    margin: 0px!important;
    width: 100%;
}
.finance .nav.nav-tabs{
    display: none;
}
#chng{overflow: hidden;}
#chng a{
    color:#333!important;
    padding:14px 0px!important;
    text-align: center;
}
#chng:hover a{
    background: linear-gradient(#ff7200,#ffb500);
}
#chng:hover a{
    color:#fff!important;
}