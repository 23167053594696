.otherPlatformLink {
    font-weight: 500;
    color: #050505;
}

.max-60 {
    max-width: 100%;
}

body {
    background-color: #f0f2f5;
}

.theme-color {
    color: #ff7200;
}

.fsize-11 {
    font-size: 11px;
}

.siderbarIco {
    filter: drop-shadow(2px 4px 1px rgba(0, 0, 0, .1));
}

.sidebar_ico {
    width: 24px;
    height: 24px;
    background: url('../../images/sidebar_ico.svg');
    background-color: rgba(207, 179, 68, 0.683);
    border-radius: 50%;
    background-size: 30px;
    background-repeat: no-repeat;
}

.sidebar_ico.qb {
    background-position: 0px -104px;
    background-color: rgb(40, 190, 190);
}

.sidebar_ico.iq {
    background-position: 0px -279px;
    background-color: rgb(104 181 211);

}

.sidebar_ico.ff {
    background-position: -3px -213px;
    background-color: rgb(152, 104, 211);

}

.sidebar_ico.di {
    background-position: 0px -210px;
    background-color: rgb(211, 104, 206);

}

.sidebar_ico.ex {
    background-position: 0px -315px;
    background-color: rgb(211, 104, 104);

}

.sidebar_ico.qu {
    background-position: 0px -139px;
    background-color: rgb(104, 211, 172);

}

.sidebar_ico.ly {
    background-position: 0px -174px;
    background-color: rgb(217, 142, 77);

}

.hover-sec {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: none;
}

.showtalent-sec:hover .hover-sec {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.postTypeButton:hover {
    background-color: #f2f2f2;
}

.chatbubble {
    min-width: 130px;
    background-color: #f2f2f2;
}

.custom-theme-button {
    background-color: #ff7200;
    border: none;
    color: white;
}

.custom-theme-button:disabled {
    color: #fff9f9;
    background-color: #ff9837;
    border-color: #ffbc7d;
}

.liked {
    filter: invert(47%) sepia(84%) saturate(3298%) hue-rotate(6deg) brightness(107%) contrast(104%) !important;
}

.share-container {
    display: flex;
    position: absolute;
    background: white;
    z-index: 1;
    box-shadow: 0px 0px 3px #ccc;
    padding: 5px 10px;
    border-radius: 4px;
    right: 125px;
}

.share-container button {
    margin: 0px 3px;
}

.loader {
    margin: 0 auto;
}

.more-option {
    position: absolute;
    background: white;
    box-shadow: 0px 0px 9px rgb(88 88 88 / 45%);
    z-index: 1;
    padding: 10px;
    right: 35px;
    min-width: 180px;
    border-radius: 10px;
    border-top-right-radius: 0;
}
.more-option span:hover{
    color:black;
    background-color: #f9f9f9;
    border-radius: 4px;
}
#signInPopup .modal-content{
    max-width: 640px;
    border-radius: 20px;
    margin:0 auto;
}
.flex-2{flex:2;}
#signInPopup .modal-body{
    padding:3rem;
}
.searchInput:focus{outline:none;box-shadow:none;}
.fixed-bottom-right{position: fixed;right:10px;bottom:10px;z-index: 1;}
.scrolled-user{
    max-height: 490px;
    overflow-y: auto;
    /* width: 104%; */
   
    overflow-x: hidden;
    padding-right: 12px;
}
#signInPopup .form-control{
    height: 50px;
    background: #f7f7f7;
    border: none;
    box-shadow: 0px 0px 3px #d1d1d1;
}
#signInPopup button{
    height:50px;
}
.thumb_name{
    text-transform: uppercase;
    display: flex;
    width:40px;
    height:40px;
    border-radius: 50%;
    background: #ff7200;
    color:white;
    align-items: center;
    justify-content: center;    
}
.fsize-12{font-size:14px;}
.sign-in-btn{
    background-color: #ff7200;
    color:white;
    padding: 0.4rem 1rem;

}
.libraries-dropdown a{
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}
.libraries-dropdown a:hover{
    color: #ff7200!important;
}
.header-center{
    max-width: 500px;
margin: 0px 6%;
}
.modal-content{border-radius: .9rem;}
.header-center svg{
    fill:#333;
    }
.header-center svg.active{
fill:#ff7200;
}
.header-center img.active{
    filter: invert(42%) sepia(82%) saturate(1090%) hue-rotate(359deg) brightness(100%) contrast(107%);
}
.rightMoreContainer{
    display: flex;
    flex-direction: row;
    align-items:center;
}
.styles-module_dark__xNqje {
    background: rgba(255, 255, 255, .9)!important;
    color: #11181C!important;
    font-size: 12px!important;
    border-radius: 14px!important;
    box-shadow: 0 12px 20px 6px rgb(104 112 118 / 0.08)!important;
    line-height: 10px;
    padding-top: 15px!important;
  }
  .more-menu{
    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate(-424px, 54px);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
    
  }
 
@media screen and (min-width:768px){
	.fixed-right{
		max-width:270px;
	}
}
@media screen and (max-width:767px){
    .left-side-bar{
        left: 0px;
        right: 0px;
        top: 0;
        background: rgb(255 255 255 / 94.8%);
        bottom: 0;
        z-index: 1;
        padding-top: 90px;
        padding-left: 20px;
    }
    .right-side-bar{display:none;}
    .show-features{margin-right:8px;}

    .bottomTabs{
        position: fixed;
        bottom: 0;
        z-index: 100; 
        background: white;
        display: flex!important;
        justify-content: space-between;
        left: 0;
        right: 0;
        box-shadow: 0px -7px 7px rgb(125 122 122 / 26%);
        padding: 7px 19px 7px;
       
    }
    .bottomTabs a {    color: #000;
        font-size: 12px;
        font-weight: bold;
        line-height: 38px;}
    .bottomTabs a img{    margin-bottom: 0px;
        margin-top: -12px;
        background: white;
        border-radius: 120px;
        padding: 3px;
        width: 36px;
        height:35px;
        padding-bottom: 0;}
        .talent-trending-list{overflow:auto!important;}
        .btn-playstore{    width: 35px;
        overflow: hidden;
        float: right;
        margin:0;
        border-radius: 7px;}
        .bp2{width:26px!important;margin-right:7px;}
        .bp2 img{width: 30px!important;
        height: 30px!important;
        margin-left: -2px;
    }
    .peoples-section{
        position: fixed;
        background: white;
        z-index: 10;
        padding: 28px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 0px 3px #333;
        overflow-y: auto;
    }
    .peoples-section.show{
        top:16%;
        bottom:0;
        left:0;
        right:0;
    }
    .peoples-section.hide{
        top:100%;
        bottom:auto;
        left:0;
        right:0;    
    }
    .lost-flex-1{flex:none!important;}
    #signInPopup .modal-body {
        padding: 2rem;
    }
}
