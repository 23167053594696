.custom-table thead th {
    background-color: #e0e0e0; /* Grayish background */
    color: #000; /* Black text */
    font-weight: bold;
    padding: 10px;
  }
  .custom-table tbody td {
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    padding: 10px; /* Add padding for better spacing */
  }
  
  .card {
    transition: box-shadow 0.2s ease-in-out;
  }
  
  /* .card:hover {
    box-shadow: none !important;
  } */
  