/*modified-bootstrap-code*/
@charset "UTF-8";
:root {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-black: #000;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-primary-rgb: 13,110,253;
	--bs-secondary-rgb: 108,117,125;
	--bs-success-rgb: 25,135,84;
	--bs-info-rgb: 13,202,240;
	--bs-warning-rgb: 255,193,7;
	--bs-danger-rgb: 220,53,69;
	--bs-light-rgb: 248,249,250;
	--bs-dark-rgb: 33,37,41;
	--bs-white-rgb: 255,255,255;
	--bs-black-rgb: 0,0,0;
	--bs-body-color-rgb: 33,37,41;
	--bs-body-bg-rgb: 255,255,255;
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: #212529;
	--bs-body-bg: #fff;
	--bs-border-width: 1px;
	--bs-border-style: solid;
	--bs-border-color: #dee2e6;
	--bs-border-color-translucent: rgba(0, 0, 0, 0.175);
	--bs-border-radius: 0.375rem;
	--bs-border-radius-sm: 0.25rem;
	--bs-border-radius-lg: 0.5rem;
	--bs-border-radius-xl: 1rem;
	--bs-border-radius-2xl: 2rem;
	--bs-border-radius-pill: 50rem;
	--bs-heading-color: ;--bs-link-color:#0d6efd;
	--bs-link-hover-color: #0a58ca;
	--bs-code-color: #d63384;
	--bs-highlight-bg: #fff3cd;
}

*,::after,::before {
	box-sizing: border-box;
}
@media (prefers-reduced-motion:no-preference) {
	:root {
		scroll-behavior: smooth;
	}
}

.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--bs-gutter-x) * .5);
	padding-left: calc(var(--bs-gutter-x) * .5);
	margin-right: auto;
	margin-left: auto;
}

#top-area {
    background: url(../images/bg_register.png) no-repeat,linear-gradient(0deg,#f4f4f4 90%,#fff 40%);
    background-size: 100%;
	padding: 12rem 0px;
    background-attachment: fixed;
}
.divider {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2rem 0;
  }
  
  .divider .line {
	flex: 1;
	height: 1px;
	background: #eeeeee;
  }
  
  .divider .or {
	padding: 0 10px;
	color: #8f8c8c;
  }
  
@media screen and (max-width:767px){
	.form-container{
		padding:1.5rem 0!important;
	}
}
@media (min-width:576px) {
	.container,.container-sm {
		max-width: 540px;
	}
}

@media (min-width:768px) {
	.container,.container-md,.container-sm {
		max-width: 720px;
	}
}

@media (min-width:992px) {
	.container,.container-lg,.container-md,.container-sm {
		max-width: 960px;
	}
}

@media (min-width:1200px) {
	.container,.container-lg,.container-md,.container-sm,.container-xl {
		max-width: 1140px;
	}
}

@media (min-width:1400px) {
	.container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
		max-width: 1320px;
	}
}