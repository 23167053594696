*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  body{
    font-size: 14px;
    font-weight: 400;
    font-family: "Droid Serif"!important;
    background-color: #F8F8F7!important;
  }
  a{
    text-decoration: none!important;
  }
  .nav_main{
    margin: 1rem 0rem;
  }
  .nav_main .nav-pills{
    justify-content: center;
  }
  .tag_steps{
    display: flex;
    border-radius: 50px;
    border: 1px solid #C2C2C2;
    background: #FAFAFA;
  }
  .nav_main .nav-pills .nav-item{
    border-radius: 50px;
    background: #FAFAFA;
    
  }
  .nav_main .nav-pills .nav-item .nav-link{
    color: #505050!important;
    font-family: Droid Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0.8rem 4rem;
  }
  
  .nav_main .nav-pills .nav-item .nav-link.active{
    border-radius: 50px;
    background: #FF7200;
    color: #fff!important;
    box-shadow: 9px 0px 14px 0px rgba(0, 0, 0, 0.09);
  }
  .tabs_boxs, .task_field, .actions_field{
    padding: 1rem 2rem;
  }
  .card{
    border-radius: 12px!important;
    border:none!important
  }
  .card .box.active{
    background: #FF7200;
    color: #FFF;
  }
  
  .card .box .tab_page_link{
    color: rgb(80 80 80 / 71%);
  }
  .card .box{
    padding: 1rem;
    border-radius: 12px;
    background: #f4f4f4;
    text-align: center;
    color: #505050;
  }
  .card .box span{
    font-size: 25px;
    opacity: 0.5;
  }
  .card .box.active .tab_page_link{
    /* background: #FF7200; */
    color: #FFF;
  }
  .card .box.active span{
    opacity: 1;
  }
  
  .card .box:hover{
    color: #ff7200;
  }
  
  .card .box .box_title{
    font-family: Droid Serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0.5rem 0rem 0rem;
  } 
  .task_box{
    border-radius: 14px;
    border: 1px solid rgb(194 194 194 / 28%);
    padding: 3rem;
    background: #FAFAFA;
    box-shadow: 0px 11px 76px 0px rgba(0, 0, 0, 0.05);
  }
  .task_box .task_box_title{
    color: #989898;
    font-family: Droid Serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 1.5rem;
  }
  .form-label{
    color: #8E8E8E;
  font-family: Droid Serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* padding-bottom: 10px; */
  }
  /* .form-control{
    border-radius: 7px!important;
    border: 1px solid #DDD!important;
    background: #FAFAFA!important;
    margin-bottom: 1rem;
    padding: 0.2rem 1rem!important;
  
  } */
  /* input.form-control,select.form-control{
    height: 44px!important;
  } */
  .form-check-label{
    color: #FF7200;
    font-family: Droid Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;
  }
  .form-check-input[type=checkbox]{
    border-radius: 3px!important;
    box-shadow: none!important;
    outline: none;
  }
  .form-check-input:checked{
    background-color: #ff7200!important;
      border-color: #ff7200!important;
  }
  .box_notes b{
    color: #343434;
    font-family: Droid Serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .box_notes span{
    color: #FF7200;
    font-family: Droid Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .box_notes{
    color: #8E8E8E;
    font-family: Droid Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .actions_field .action_title{
    color: #525252;
    font-family: Droid Serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 0.7rem;
  }
  .actions_field .action_para{
    color: #3E3E3E;
    font-family: Droid Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px; /* 147.619% */
  }
  .action_btn_box{
    display: flex;
      justify-content: end;
  }
  .action_btn_box .add_action_btn{
    padding: 0.8rem 2rem;
    color: #FFF;
    font-family: Droid Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .btn-primary{
    background-color: #ff7200!important;
      border-color: #ff7200!important;
  }
  .form_lavel_checkbox{
    display: flex;
    align-items: center;
    gap: 7rem;
  }
  .form-check .proof_lavel{
    color: #8E8E8E;
    font-family: Droid Serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  
  #drop-area {
    border: 2px dashed #aaa;
    padding: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border-radius: 14px;
    min-height: 2in;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
  }
  
  .drop-text {
    font-size: 18px;
    color: #3E3E3E;
    font-family: Droid Serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  #dropped-content {
    width: calc(100% - 23px);
    height: 200px;
    min-height: 69px;
    max-height: 312px;
    margin-top: 20px;
    font-size: 16px;
    padding: 20px;
    resize: vertical;
    color: #fff;
    outline: none;
    border-radius: 10px;
    border: 1px solid rgba(221, 221, 221, 0.87);
    background: #FAFAFA;
  }
  
  #dropped-content::-webkit-scrollbar {
    width: 8px;
  }
  
  #dropped-content::-webkit-scrollbar-track {
    background-color: #444;
  }
  
  #dropped-content::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  #dropped-content::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }
  
  .drop-icon i {
    color: #888;
    font-size: 4.5rem;
  }
  
  #chars {
    color: #888;
    float: right;
  }
  
  #spellcheck {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  #outer-dot {
    background-color: #007bff;
    width: 3rem;
    height: 1.5rem;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background 200ms;
  }
  
  #dot {
    background-color: #eee;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin: 0 5px;
    transform: translateX(22px);
    transition: transform 200ms;
  }
  .upload_btn_box .browse_btn{
    padding: 0.8rem 2rem;
    color: #FFF;
    font-family: Droid Serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .action_title span{
    color: #FF7200;
    font-family: Droid Serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .submit_form_box{
    margin: 2rem 0rem;
  }
  .search_box{
    position: relative;
  }
  .search_icon{
    position: absolute;
      top: 10px;
      left: 20px;
      color: #c5c1c1;
  }
  .table>thead{
    background: #F4F4F4;
  }
  .table>body{
    background: #FEFEFE;
  }
  .table thead tr th{
    color: #3E3E3E;
    font-family: Droid Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px 10px;   
  }
  .table tbody tr td{
    color: #8E8E8E;
    font-family: Droid Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15px 10px;
  }
  .status_check{
    padding: 6px;
    color: #8E8E8E;
    font-family: Droid Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #8E8E8E;
    background: #FFF;
  }
  .status_check:hover, .status_check:focus{
    border: 1px solid #8E8E8E; 
  }
  .text-orange{
    color: #ff7200!important;
  }
  .card_box{
    border-radius: 12px;
    background: #FAFAFA;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    /* align-items: baseline; */
  }
  .task-cell{
    max-width: 330px;
  }
  .box_icons{
    font-size: 50px;
    color: #FF7200;
    opacity: 0.7;
  }
  .card_lavel{
    color: #585858;
    font-family: Droid Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .card_point{
    color: #505050;
    font-family: Droid Serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 0px;
    margin: 0;
  }
  .funds_lebel{
    color: #3E3E3E;
    font-family: Droid Serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .funds_lebel span{
    color: #8E8E8E;
    font-size: 14px;
  }
  .traffic-source{
    color: #8E8E8E;
    font-family: Droid Serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .traffic-source span{
    color: #FF7200;
    font-family: Droid Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .table_filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .filter_fame{
    border-radius: 5px;
    border: 1px solid #CCC;
    background: #FAFAFA;
    padding: 7px;
    margin-right: 10px;
  }
  /* landing page design */
  .banners img{
    border-radius: 6px;
    /* background: url(<path-to-image>), lightgray -14px 0px / 103.704% 100% no-repeat; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
  .bg_hero{
    padding: 1rem 2rem;
  }
  .content_earn .earn_title{
    color: #525252;
      font-family: Droid Serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-bottom: 0.7rem;
  }
  .content_earn .earn_para{
    color: #3E3E3E;
      font-family: Droid Serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
  }
  .content_graphic{
    padding: 1rem;
  }
  .card_content{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    background-color: #fff;
    padding: 1rem 3rem;
    align-items: center;
  }
  input:hover, input:focus{
    box-shadow: none!important;
  }