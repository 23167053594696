/*for desktop 1200px b/w 1499px*/

@media screen and (max-width: 1499px) and (min-width: 1200px){
	
	.menu-box{
		padding-left: 0rem!important;

	}
	.small-icon-logo{
		padding: 0.8rem 1rem 0.5rem;
	}
	.menu-box ul{
		display: block;
    margin: auto;
    padding: 2rem 0rem 2rem 0.3rem;
	}
	.heading-star {
		font-size: 18px;
	}
	.job-card div{
		/*padding-right: 5rem;*/
	}
	.no-menu li{
		font-size: 14px;
		font-weight: 600;
	}
	.responsive-card{
    	margin: 0;
	}
	.job-box div{
		font-size: 11px;
    	font-weight: 600;
	}
	.category-text{
		font-size: 18px!important;
	}
	.category-main-box .category-name h3{
		font-size: 16px;
	}
	.question-box .btn-soft{
		padding: 0.7rem 0.5rem;
		
	}
	.reply-box h2 span{
		padding: 0rem 1rem;
	}
	.fixed-box{
		width: auto;
		height: auto;
		padding: 0.8rem	!important;
	}
	.video-discription{
		/*line-height: inherit!important;*/
		font-size: 15px;
	}
	.responsive-main-card{
		margin: 1rem 1.5rem 1rem 0rem!important;
    	column-gap: 0.8rem!important;
    	row-gap: 1rem!important;
	}
	.wallet-menu{
		font-size: 16px!important;
		font-weight: 500!important;
	}
	thead .table-heading th {
    padding: 1rem 0.5rem!important;
    font-size: 15px;
	}
	.plan-main-box{
		column-gap: 1rem;
	}
	.plan-main-box .active-plan, .plan-main-box .basic-plan{
		padding: 1rem;
	}
	.plan-icon img {
    	width: 55px;
	}
	.plan-heading{
		padding:0rem 0.5rem!important;
	}
	.plan-heading h4{
		font-size: 14px;
	}
	.plan-details{
		font-size: 15px;
	}
	tbody .table-design td, tbody .table-label td, thead .table-heading th{
		font-size: 14px;
		/*padding: 1.6rem 0.4rem!important;*/
	}
	.profile-menu{
		height: 140px;
	}
	.profile-detail p{
		line-height: 18px;
	}
	.profile-detail a i{
		padding: 5px 5px 5px 0px;
	}
	.font-semibold{
		font-weight: 500!important;
	}
	.dropdown-submenu ul{
		padding: 0.5rem 0rem 0rem 0rem;
	}
}

	/*for mobile*/
	@media screen and (min-width: 900px){
	.mobile-view{
		display: none!important;
	}
}
	@media screen and (max-width: 900px){
		.desktop-view{
			display: none!important;
		}
		/*body{
			overflow: hidden;
		}*/
		.logo img{
			width: 115px;
		}
		.menu-box{
			width: 0%;
			padding-left: 0px!important;
			padding-top: 0!important;
			z-index: 999;
		}
		.menu-box.hide{
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
		.services-title-h{
			flex-wrap: wrap;
			margin: 0 !important;
		}
		.services-title-h .input-header, .services-title-h .qlist-header{
			position: relative;
			width: 100%;
		}
		.services-title-h .input-header label, .services-title-h .qlist-header label{
			position: relative;
			width: 70%;
			margin: auto;margin-top: 10px;
		}
		.services-title-h .input-header label svg, .services-title-h .qlist-header label svg{
			width: 14px;
		}
		.services-title-h .qlist-header label{
			width: 95%;
		}
		.fav-btn{
			min-width: auto;
			margin-top: 12px;
			padding: 0.4rem 1rem;
		}
		.services-title-h .input-header label span, .services-title-h .qlist-header label span{
			z-index: 9;
		}
		.services-title-h .input-header input, .services-title-h .qlist-header input{
			position: relative;
			width: 100%;
			background-image: none;
			padding-left: 45px !important;
			height: 35px;
		}
		.content-box.full {
			width: 0%;
			overflow: hidden;
		}
		.main-card{
			column-gap: 0px!important;
			height: 100vh;
		}
		/*.search-card-icon{
			margin-left: 0!important;
		}*/
		.search-card{
			margin-left: 0!important;
			/* display: none!important; */
		}
		.profile-box{
			position: fixed;
			right: 0;
			top: 0;
			z-index: 9999;
		}
		.profile-box .prifile-name{
			display: none;
		}
		.profile-header li .profile-img{
			width: 40px;
    		height: 40px;
		}
		.menu-box ul{
			padding-top: 5rem!important;
		}
		.menu-box.hide .item .item-list{
			width: 100%;
			padding: 0.5rem 0rem 0.5rem 0rem;
			margin: 0rem 0.5rem;
		}
		.menu-box.hide .item .item-list .item-link:hover{
			width: 95%;
    		background: antiquewhite;
    		border-radius: 1rem;
    		/*margin: 0rem 1rem;*/
		}
		.breadcrumb-main-mobile li{
			max-width:75px;
			overflow: hidden;
			white-space: nowrap; 
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.menu-toggle i{
			margin-top: 0px;
			margin-left: 0px;
			padding: 1rem;
		}
		.logo img {
    		padding: 0.5rem 0rem !important;
		}
		.card-img img{
			min-height: auto !important;
			height: 120px !important;
	    }
		.heading-t-s{
			width: 100% !important;
		}
		.feedback-item{
			flex-wrap: wrap !important;
		}
		#qBank{
			max-height: calc(100vh - 230px) !important;
		}
		#qCat{
			max-height: calc(100vh - 230px) !important;
		}
		#qlist{
			max-height: calc(100vh - 270px) !important;
		}
		#qlist.fb-q{
			max-height: calc(100vh - 200px) !important;
		}
		.notification-card, .search-card-icon{
			margin: 1rem 0.4rem;
    		padding: 0.2rem 0.6rem;
		}
		.notification-card-explore{
			padding: 0.4rem 0.5rem;
		}
		.top-menu{
			position: fixed;
			width: 100%;
			padding-right: 1rem;
			z-index: 999;
			background: #fff /*#f08645*/;
		}
		.content-box{
			width: 100% !important;
		}
		.card-heading-box{
			padding: 7rem 1.5rem 1rem!important;
		}
		.heading-star{
			font-size: 21px;
		}
		.skip-text {
			padding: 0rem 0.5rem;
			font-size: 16px;
		}
		.card-1{
			margin: 0rem 1.5rem!important;
		}
		.menu-box.hide .item .item-list .item-link svg {
			margin-right: 10px;
		}
		.dropdown-submenu ul{
			padding: 0rem 0rem 0rem 2.4rem!important;
		}
		.menu-toggle i, .notification-card i, .search-card-icon i{
			color: #a5a5a5;
		}
		/*.search-card-icon:focus {
  			width: 100%;
  			border: 1px solid #ccc;
  			z-index: 999;
  			padding: 6px 15px 6px 40px;
	  		-webkit-transition: width 0.4s ease-in-out;
	  		transition: width 0.4s ease-in-out;
	  		box-sizing: border-box;	  		
		}*/
		.none{
			display: none!important;
		}
		.block-card{
			display: block!important;
			row-gap: 1rem;
		}
		.graph-card-box{
			padding: 5rem 1rem 1rem!important;
			padding-top: 0 !important;
		}
		.tab-menu ul{
			align-items: center;	
		}
		.tab-menu ul li{
			padding: 0.5rem;
		}
		.tab-menu ul li a {
			font-size: 14px;
		}
		.news-subscribe{
			padding: 2rem;
			margin-top: 1rem;
		}
		.bottom-card-box{
			padding: 0rem 1rem 1rem!important;
		}
		.target-card{
			margin-top: 1rem;
		}
		/*.job-card{
			justify-content: space-between;
		}
		.job-card div{
			padding-right: 0rem;
		}*/
		.q-breadcum .arr-r svg{
			width: 18px;
		}
		.breadcrumb-card{
			/* display: none; */
			/* margin-top: 4.6rem; */
		}
		.breadcrumb a{
			font-size: 10px;
		}
		.text-card{
			grid-column: span 12 / span 12!important;
		}
		.text-card h2{
			padding: 1rem 0rem 0rem!important;
			justify-content:  center !important;
			text-align: center;
		}
		.menu-box{
			width: 0;
		}
		.multi-card{
			grid-template-columns: repeat(2, minmax(0, 1fr))!important;
			padding:0rem 1rem 1rem!important;
		}
		.card-img{
			margin-right: 0rem!important;
			border-radius: 1rem!important;
		}
		.category-text{
			font-size: 12px!important;
    		line-height: 17px!important;
    		font-weight: 600!important;
    		margin-bottom: 0px!important;
		}
		.child-box{
			padding: 0.5rem!important;
		}
		.breadcrumb{
			line-height: 12px;
			font-size: 10px;
		}
		.category-main-box img {
			min-width: 80px !important;
			max-width: 80px  !important;
			height: 60px  !important;
		}
		.q-breadcum .breadcrumb{
			line-height: 12px;
			font-size: 8px;
		}
		.breadcrumb a{
			font-size: 10px;
			font-weight: 400;
		}
		.breadcrumb-item2+.breadcrumb-item2::before{
			padding: 0rem 0.1rem;
			font-size: 8px;
		}
		.child-box nav a svg{
			width: 15px!important;
    		height: 15px!important;
    		margin-top: 5px;
		}
		.group-card{
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;	
			margin: 1rem 0rem!important;
			margin-top: 0 !important;
		}
		.detail-card-box{
			padding:1rem!important;
		}
		.feed-card{
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;
			padding: 1rem 0rem!important;	
		}
		.subscribe_btn {
			display: block;
    		text-align: center;
		}
		.child-content li{
			padding: 0.5rem;
			padding-left: 0 !important;
			/*align-items: baseline;*/
		}
		.child-content li  img{
 		padding: 0.6rem 0.8rem 0rem 0rem;
 		}
		.detail-card-box .text-card{
			padding-left: 0rem!important;
		}
		.feed-profile-box{
			padding: 0.5rem 1.2rem!important;
		}
		.small-logo-box img {
			width: 52px;
		}
		.btn-box {
			padding: 0.6rem 1.5rem;
			border-radius: 0.6rem;
			font-weight: 400;
    		font-size: 14px;
		}
		.small-logo-text{
			padding-left: 0.5rem!important;
		}
		.small-logo-text p{
			font-size: 12px;
		}
		.text-icon-box .text-group h3{
			font-size: 15px !important;
			font-weight: 500 !important;
		}
		.text-icon-box .text-group p{
			font-size: 12px;
			font-weight: 500;
		}
		.small-logo-text a {
			font-size: 16px !important;
			padding: 0rem 0.2rem !important;
		}
		.question-box{
			padding: 1.3rem 1.2rem 0rem!important;
			align-items: flex-start!important;
			padding-top: 0 !important;
		}
		.divider{
			display: none;
		}
		.question-box h1 {
			font-size: 15px;
    		font-weight: 600;
		}
		.feedback-item li{
			font-size: 10px;
    		font-weight: 500;
		}
		.answer-card{
			margin-left: 1.2rem!important;
		}
		.question-box-card{
			display: flex;
			flex-wrap: wrap;
			margin: 0rem!important;
			padding-top: 0rem!important;
		}
		.question-box-card .btn-soft, .question-box .btn-soft{
			font-size: 12px;
			font-size: 400!important;
			/*padding: 0.7rem 0.5rem;*/
    		border-radius: 0.5rem;
    		margin: 3px!important;
    		display: flex;
		} 
		.comment-profile img {
			min-width: 40px !important;
			min-height: 40px !important;
			max-width: 40px !important;
			max-height: 40px !important;
			border-radius: 7px;
		}
		.reply-box h2{
			font-size: 15px;
		}
		.reply-box h2 span{
			padding: 0rem;
		}
		.reply-box p {
			font-size: 13px;
		}
		.heading-card-box{
			padding: 1.3rem 1.2rem;
		}
		.accordion-card{
			padding: 0rem 0.5rem!important;
		}
		.fixed-box{
			width: 100%;
			height: 100%;
			/*margin: 0.5rem;*/
		}
		.help-desk-card{
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;	
			margin:0rem 1.5rem 1rem!important;
			gap: 1rem!important;
		}
		.setting-desk-card{
			grid-template-columns: repeat(2, minmax(0, 1fr))!important;	
			margin:0rem 1.2rem 1rem!important;
			gap: 0.6rem!important;
		}
		.card-btn-detail{
			margin: 1rem!important;
		}
		.wallet-card {
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;	
			margin: 6rem 0rem 0rem!important;
		}
		.member-card{
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;	
			margin: 4rem 0rem 0rem!important;
		}
		.table_container{
			width: 100%;
			overflow-x: scroll;
			width: 100%;
			margin: 0rem!important;
			padding: 0rem 1rem;
		}
		.table{
			width: max-content!important;
		}
		tbody .table-label td {
			padding: 0rem 0.6rem!important;
		}
		.bank_tax_card{
			margin: 1rem!important;
			padding: 2rem 1rem!important;
		}
		.multi-card{
			gap: 0.6rem!important;
			flex-wrap: nowrap!important;
		}
		.withdrawal-card{
			margin: 1rem!important;
			padding: 1rem!important;
		}
		.withdrawal-card form .form-card{
			display: block;
		}
		.withdrawal-card form .form-card .input-card{
			width: 100%;
		}
		.withdrawal-card form .form-card .request_btn{
			margin: 1rem 0rem;
    		padding: 0.3rem 3rem!important;
		}
		.forum-card{
			margin: 0rem!important;
		}
		.information-forum{
			margin: 0rem!important;
			padding: 1rem!important;
			display: block!important;
		}
		.information-forum .content-card{
			padding:1rem 0rem!important;
		}
		.information-forum h3 {
			font-size: 16px;
		}
		.add-discussion-btn{
			display: block;
			text-align: center;
			margin: 2rem 1rem;
		}
		.btn_card{
			margin: 0!important;
		}
		.profile-desk-card{
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;	
			margin:0rem 1rem 1rem!important;
			gap: 0rem!important;
		}
		.Edit-btn{
			padding: 0.5rem 1rem;
    		border-radius: 0.6rem;
    		margin: 1rem!important;
		}
		.profile-card{
			display: flex!important;
			justify-content: space-between!important;
			margin-bottom: 2rem!important;
		}
		.profile-detail-card{
			grid-column: span 12 / span 12!important;
		}
		.profile-menu{
			padding: 1rem!important;
		}
		.profile-menu ul{
			display: flex!important;
			align-items: center!important;
			flex-wrap: nowrap!important;
		}
		.profile-menu ul li{
			padding: 0rem!important;
		}
		.profile-menu ul li a{
			font-size: 10px!important;
			font-weight: 400!important;
		}
		.profile-menu ul li a svg{
			width: 18px!important;
			margin-bottom: 0rem!important;
		}
		.content-item li,.about-box p{
			font-size: 14px;
		}
		.table-card{
			padding: 1rem!important;
		}
		thead .table-heading th {
			padding: 1rem!important;
		}
		.sub-box-card{
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;
			padding: 1rem 0rem!important;	
			margin: 1rem 0rem!important;
		}
		.cart-card{
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;
			margin-top: 3rem;
		}
		.cart-detail-box{
			padding: 1rem 1rem 1rem 1rem;
		}
		.cart-detail-card{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.Add-card-btn {
			font-size: 14px;
			padding: 0.6rem 0.8rem!important;
			margin-top: 0rem!important;
		}
		.cart-detail-box, .news-subscribe, .question-card{
			grid-column: span 12 / span 12!important;
		}
		.question-card{
			margin-top: 1rem;
			padding: 1rem;
		}
		.news-subscribe{
			margin: 1rem!important;
			padding: 0.2rem!important;
		}
		.checkout-btn{
			margin: 2rem 0rem 0rem;
		}
		.modal-card{
			width: 100%!important;
			margin: 1rem!important;
		}
		#overlay{
			z-index: 99999;
		}
		.box-2 a .fa-check-square {
			font-size: 20px;
		}
		.sub-breadcrumb-card .breadcrumb-sub-card .breadcrumb-item a{
			font-size: 16px!important;
		}
		.content h2{
			font-size: 17px;
		}
		.main-tab-card{
			width: 100%;
			display: grid;
    		place-items: center;
    		margin-top: 3rem;
		}
		.tab-card{
			position: fixed;
    	    bottom: 1.6rem;
    		background: #fff;
    		border-radius: 2rem;
    		padding:0rem 1rem;
    		/*width: 100%;*/
		}
		input[type=text] {
  			/* width: 0px;
			box-sizing: border-box; */
			/*border: 2px solid #ccc;*/
			/*border-radius: 1rem;*/
			/* font-size: 16px;
			background-color: white;
			background-image: url('../icons/mobile-search.jpg'); */
			/*background-position: 10px 10px; */
			/* background-position: center;
			background-repeat: no-repeat; */
			/* padding: 0.2rem 1rem; */
			/*padding: 0px 10px 0px 10px;*/
			-webkit-transition: width 0.4s ease-in-out;
			transition: width 0.4s ease-in-out;
		}
		.question-box .comment-box{
			height: 40px;
			/* margin-bottom: 10px; */
		}
		.reply-box{
			margin-bottom: 15px;
		}
		input[type=text]:focus {
		  width: 100%;
		  background: #fff;
		  border: none;
		  outline: none;
		  padding: 10px 15px;
		  border-radius: 3rem;
		  border: 1px solid #ccc;
		}
		.menu-box{
			height: 100vh;
			overflow-y: auto;
		}
		#pop_ads{
			display: block;
		}
		.information .element-heading {
			font-size: 11px;
			font-weight: 700;
		}
		.feeds{
			padding: 0rem!important;
		}
		.upload{
			margin: 1rem 0.5rem 0rem;
		}
		.feeds-uploads{
			padding: 0rem 0.5rem;
		}
		.details_feed_card{
			font-size: 14px;
			padding: 0.5rem 1rem!important;
		}
		.dropdown-submenu.show {
			margin-left: 0.5rem;
		}
		.job_card_container{
			padding: 1rem;
		}
		.job_card_container img{
			width: 46px;
		}
		.small_tag_card{
			border-radius: 0.6rem;
		}
		.edit-profile-card{
			grid-template-columns: repeat(1, minmax(0, 1fr))!important;
		}
		.mobile_tab_card{
			font-size: 10px;
    		text-align: center;
    		color: #a5a5a5;
    		font-weight: 400;
		}
		.video_container{
			margin: 20rem 0rem;
		}
.new-com-select{
	margin-bottom: 20px;
}
.question-box h2{
	font-size: 16px;
}
#mViewQ span{
	font-size: 14px;
}
.new-com-select{
	margin-top: 10px;
}
}
@media(min-width: 768px) and (max-width: 900px){
	.profile-box .prifile-name{
		display: block;
	}
	.breadcrumb-main-mobile li{
		max-width: 361px;
	}
	.card-img img{
		height: 173px !important;
	}
	.category-text{
		font-size: 14px !important;
		font-weight: 600 !important;
	}
	.q-breadcum .arr-r svg{
		width: 30px;
	}
	.q-breadcum .breadcrumb{
		font-size: 12px;
	}
	.services-title-h .input-header input, .services-title-h .qlist-header input{
		height: 60px;
	}
	
}
@media screen and (min-width:768px) {
	.mh-480{min-height: 480px!important;}
	.card-exam-result-pie{
		margin-top:.5rem;
		position: sticky!important;
		top: 6rem;
		height: calc(100vh - 18rem)!important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.card-exam-result-pie .card-body{
		display: flex;
    	align-items: center;
	}
}
@media screen and (max-width:767px){
	.filter-result-exam select{
		width:100%!important;
		margin-bottom: 1rem;
	}
	.filter-result-exam .dropdown > button{
		width:100%!important;
	}
	.row-mob{
		margin:0px!important;
	}
	.ex-container-list img{
		width: 100%;
   		margin-bottom: 1rem;
	}
	.c-menu:not(.show-chapters-mob){
		display: none!important;
	}
	/* li.breadcrumb-item.clamping.ellipsis{
		display:none;
	} */
	.top-leader-board-container{
		position:relative!important;
		top:0!important;
	}
	.stats-container{
		display: grid!important;
		grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
		gap: 20px;
	}
	.multi-card{
		grid-template-columns: repeat(1, minmax(0, 1fr))!important;
		padding:0rem 1rem 1rem!important;
	}
	.mainQ-title{
		padding:0;
	}
	.mainQ-title:before{
		content: none;
	}
	.meta-left.gap-20{
		display: grid !important;
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    gap: 10px;
    margin-bottom: 20px;
	}
	.implinks{
		display: grid !important;
    	grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
	}
	.implinks a{
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 1rem;
		gap: 11px;
		min-height: 99px;
    	justify-content: center;
	}
	.items-start.question-box{
		padding:0px!important;
	}
	.items-start.question-box h2{
		font-size: 16px!important;
	}
	.reply-container{
		position: absolute!important;
		background: white;
		left: 20px!important;
		right: 20px!important;
	}
	.reply-container > div > input{
		margin: 0;
		width: 100%!important;
		margin-bottom: 5px;
	}
	.reply-container > div > button{
		width: 100%;
		text-align: center;
		justify-content: center;
	}
	.reply-box .question-box{
		padding-left: 0px!important;
		padding-right: 0px!important;
	}
	#qBank.gap-4{
		gap:10px!important;
	}
	.btn-group.question-box{
		padding: 0px !important;
   		margin-top: 1.3rem !important;
	}
	html{
		padding:0!important;
	}
	#pills-tab .nav-link {
		padding: 8px 15px !important;
	}
	.edit-sec{
		position: absolute;
		bottom: 6px;
		left: 3px;
	}
	.css-13cymwt-control, .css-b62m3t-container{
		height: auto!important;
	}
	.profile-dropdowns.mw-300{
		min-width: 78vw;
	}
	#sbar{
		position: fixed !important;
		left: 0;
		right: 0;
		top: 4.3rem !important;
		max-height: calc(100% - 4.3rem);
		padding: 20px 20px !important;
		/* background: rgba(0, 0, 0, 0.3) !important; */
		background: rgb(161 161 161 / 84%) !important;
	}
	#sbar .left-side-bar{
		border-radius: 20px;
		scroll-behavior: smooth;
		overflow: overlay;
		scrollbar-width: none;
		box-shadow: 10px 47px 30px #ffdfb094 inset;

	}
	.show-chapters-mob{
		position: absolute !important;
		left: 5px;
		right: 5px;
		top: 11rem!important;
		box-shadow: 0px 5px 5px rgb(145 145 145 / 60%);
		z-index: 2;
		background: white;
		border-radius: 6px;
		height: calc(100vh - 11rem);
		overflow-y: auto;
	}

	.top-banner-part{
		background-size: cover!important;
	}
	.profile-initial-info{
		margin-top: 30px;
	}
	.m-select{
		max-width: 70px !important;
		padding: 0px 13px 0px 10px !important;
		height: 20px !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
		display: flex !important;
		margin-right: 5px !important;
	}
	.search-box i.fa-search{
		color: rgb(255, 114, 0);
		font-size: 15px!important;
		background: white;
		padding: 7px;
		border-radius: 20px;
	}
	.h-section h2{
		font-size: 1.2rem !important;
		margin-bottom: 1.3rem!important;
		font-weight: 600;
	}
	.h-section.no-m h2{
		margin-bottom: 0rem!important;
	}
	.c-row{
		display: grid!important;
		grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
	}
	.credit-row{
		display: grid!important;
		grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
	}
	.credit-row > div{
		padding: 0px 10px;
		margin-bottom: 10px;
	}
	.credit-row img{
		width: 40px!important;
	}
	.c-row > div.my-2{
		padding:0px!important;

	}
	.c-row > div.my-2 > div{
		height:100%;
	}
	.w-mob-100{
		width: 100%!important;
	}
}