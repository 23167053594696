
.container {
  padding: 0 2rem;
  background-color: #f0f2f5;
  
}
.max500{
  max-width: 500px;
}
.imgcircle{
  border-radius: 100%;
}
.postedImage{
  position: relative;
  width:'100%';
  /* height: 90vh; */
  
  
}
.postedbyname{
  color:#050505;
  font-weight: 500;
}
.postedTime{
  font-size: 13px;
    color: #585858;
}
.postFooter{
  border-top-color: #f5f5f5;
}
.moreBtn{
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 30px;
  cursor: pointer;
  transform: rotate(90deg);
    color: #585858;
    margin-left:5px;
}
.moreBtn:hover{
  background: #f0f0f0;
}
.icons{
  width: 18px;
  height: 18px;
  float: left;
  overflow: hidden;
  position: relative;
  background-image: url(../../images/sprite.png);
  background-repeat: no-repeat;
  filter:invert(39%) sepia(21%) saturate(200%) saturate(109.5%) hue-rotate(174deg) brightness(94%) contrast(86%);
}
.likeIco{
  
  background-position: 0 -272px;
}
.commentIco{
  
  background-position: 0 -234px;
}
.shareIco{  
  background-position: 0 -291px;
}

.postcard{
  background-color: white;
  border-radius:8px;
  margin-bottom:20px;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
}
.sidebarLinkTxt{
  color:#050505;
  font-weight: 600;
}
