html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Droid Serif',serif!important;
  /* height:100%; */
  width: 100%;
  /* background-color: #f0f2f5; */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.postCommentFrm{
  background: #f0f2f5;
  flex: 1;
  border-radius: 100px;
  display: flex;
  padding: 10px 15px;
}
.commentInput,.commentInput:focus{
flex:1;
background-color: transparent;
border: none;
box-shadow: none;
outline:none;
}
.header-top a{font-size: 12px;
  line-height: 31px;}

  .search-box{
    background: #f7f7ff;
    border-radius:5px;
    padding:0px 5px;
    height:50px;
    flex:1;
}
.searchInput{
  flex:1;
}
.searchInput:focus{
    outline:none;
    box-shadow: none;
    flex:1;
}

