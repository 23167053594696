

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-bottom: 40px;
}
.pagination .page-item {
  width: 40px;
  height: 40px;
  text-align: center;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.pagination .page-item .page-link:hover {
  background: #ffedd5;
  border-radius: 13px;
}
.pagination .page-item:nth-child(2) {
  border-left: 1px solid #dee2e6;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.pagination .page-item:nth-last-child(-n + 2) {
  border-right: 1px solid #dee2e6;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item.active .page-link {
  background: #f97316;
  border-radius: 13px;
  color: #fff;
  width: 40px;
  height: 40px;
}
.page-link {
  padding: 0.375rem 0.75rem;
  color: #202020;
  text-align: center;
  width: 40px;
  height: 40px;
  display: block;
}
/* .page-link {
  position: relative;
  display: block;
  
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */
.prev-btn {
  margin-right: 15px;
  border: 1px solid #dee2e6;
  border-radius: 13px;
  border-radius: 0.375rem;
  font-weight: bold;
  color: #f97316;
  
}
.next-btn {
  margin-left: 15px;
  border: 1px solid #dee2e6;
  border-radius: 13px;
  border-radius: 0.375rem;
  font-weight: bold;
  color: #f97316;
}

.card-img img {
  /*width: 100%;
height: auto;*/
border-top-left-radius: 6px;
border-top-right-radius: 6px;
  -webkit-clip-path: polygon(
    100% 0%,
    100% 100%,
    100% 100%,
    100% 92%,
    76% 86%,
    0% 70%,
    0% 0%
  );
  clip-path: polygon(
    100% 0%,
    100% 100%,
    100% 100%,
    100% 92%,
    76% 86%,
    0% 70%,
    0% 0%
  );
  height: 203px;
}
.empty-icons {
  display: flex !important;
}
.filled-icons {
  display: flex !important;
}
.modal-submit-btn {
  background-color: #f08645 !important;
  padding: 0.6rem 1.5rem;
  border-radius: 0.5rem;
  border-radius: 0.375rem;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
.heading-t-s {
  width: 40%;
}
.input-header {
  position: absolute;
  /* left: 0; */
  right: 0;
  /* margin: auto; */
  top: 0;
  width: 30%;
}
.lineelip2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 26px;

  max-height: 52px;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.password-toggle-button{
  position: absolute;
  right: 10px;
  top: 7px;
  color: #5858;
}
.verify-button,
.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 5px;
}

.otp-input {
  padding: 5px;
  margin: 5px;
}

.strip-box{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 13px;
}

.otp-box{
  width: 90px;
    border-radius: 4px;
    margin: 0px 10px;
    height: 25px;
    outline:0;
    text-align: center;
    color:black;
}
#token:focus{
  /* width: 90px!important; */
    border-radius: 4px!important;
    /* margin: 0px 10px!important; */
    height: 25px!important;
    outline:0;
    text-align: center;
    color:black;
}
#questAns h1{
  font-size: 1.2rem;
  font-weight: bold;
}
#questAns h3{
  font-size: 1rem;
  font-weight: bold;
}
.reply-container{
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  padding: 1rem;
  border-radius: 8px;
  position:relative;
  border-top:3px solid #f0f0f0;
}
.reply-container:before{
  content:'';
  position:absolute;
  width:20px;
  height: 20px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid #f0f0f0;
  right:10px;
  top:-40px
}
@media screen and (min-width:768px){
  .top-banner-part{
    background-size: 100%!important;
  }
  .w-md-auto{width:auto!important}
  #d-md-inline-table{
    display: inline-table!important;
  }
  .otp-box{
    width: 130px!important;
  }
}
.btnotpverify{
  background: #ff7200;
  padding: 3px 29px;
    border-radius: 4px;
    border-radius: 0.375rem;
  border:none;
}
.error-msg-otp{
  position: absolute;
    font-size: 10px;
    left: 0;
    right: 0;
    text-align: center;
    bottom: -15px;
}
.loader-redirect{
  display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #ff7200;
    font-size: 2rem;
}
.title-job{font-size: 1.4rem;}
.leads strong{
  font-size: .9rem;
  font-weight: 600!important;
}
.leads{
  font-size: .9rem;
}
.left-side-bar a,.left-side-bar span, .left-side-bar select{
  font-size: .9rem;
}
#questAns {
  overflow-x: auto;
  background-color: #f6ffec;
  margin-top: 22px;
  padding: 15px 20px 10px;
}
#questAns h5 strong {
  color: #ff7200;
  font-size: 16px;
}
.nav-tabs .nav-link{
  color:#7a7a7a;
  padding-bottom: 0.49rem;
  border:none;
}
.nav-tabs .nav-link:hover{
  color:#7a7a7a;
  border:none;
  border-bottom: 1px solid #fe7100!important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  padding-bottom: 0.49rem;
  border: none;
  border-bottom: 1px solid #fe7100!important;
}


/* gigs styling */
.list-alter{
  border-radius: 7px;
  border-left: 3px solid #fff;
}
.list-alter:nth-child(odd){
  background: #f9fafa!important;
  border-radius: 7px;
  border-left: 3px solid #f9fafa;
}
.c-blue{
  color:rgb(0 156 255 / 80%)!important
}
.gig-table .table td, .gig-table .table th{
border:none!important;

}
.gig-table .table-striped tbody tr{
  border-left:2px solid #fff;
}
.gig-table .table-striped tbody tr:nth-of-type(odd){
background-color: rgb(173 210 230 / 5%)!important;
}
.gig-table .table-striped tbody tr:hover{
border-left:2px solid #ff7200;
}
.gig-table .btn-0{
padding: 5px 10px;
background: transparent;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #585858;
}
.gig-table .btn-0:hover{
padding: 5px 10px;
background: #f0f0f0;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #ff7200;
}

.gig-table .btn-2{
border: none;
padding: 5px 10px;
background: #fd8181;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #fdfdfd;
}
.gig-table .btn-2:hover{
border:none;
padding: 5px 10px;
background: #fa5b5b;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #fff;
}

.gig-table .btn-3{
border:none;

padding: 5px 10px;
background: transparent;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #585858;
}
.gig-table .btn-3:hover{
border:none;

padding: 5px 10px;
background: #f0f0f0;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #ff7200;
}

.gig-table .btn-1{
padding: 5px 10px;
background: #5bd796;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #ffffff!important;
}
.gig-table .btn-1:hover{
padding: 5px 10px;
background: #ff7200;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #fff;
}
.pr{
color: rgb(0 156 255 / 80%)!important;
margin-left: 6px;
}
span.sep {
padding: 4px;
font-size: 10px;
line-height: 4px;
}
.fsize-meta{
font-size: 0.8rem;
/* display: flex;
align-items: center; */
display: inline-block;
word-break: break-word;
}
.gig-table td{
vertical-align: middle;
}
/* styles.css */

.task-cell {
vertical-align: middle;
padding: 10px;
}
.clsModal:focus,.clsModal{outline:none!important;}
.thumbnail-container {
/* min-width: 32px;
float: left; */
min-width: 70px;
float: left;
height: 70px;
position: relative;
overflow: hidden;
border-radius: 7px;
}
.text-black-70{
  color: rgb(0 0 0 / 69%);
}
.thumbnail-container img{
/* max-width: 134px;
height: auto!important;
min-width:100%!important;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%); */
height: auto!important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 74px;
    max-height: 74px;
    width: auto;
    min-width: 74px;
}

.placeholder-thumbnail {
color: rgb(204, 204, 204);
width: 72px;
height: 57px;
margin-top: -2px;
opacity: 0.1;
}
.no-wrap{
  white-space: nowrap;
}
.task-details {
width: calc(100% - 28%); /* Adjust based on your layout */
}

.budget-cell,
.credits-cell,
.status-cell {
vertical-align: middle;
text-align: center;
}

.label-warning {
color: #f0ad4e;
}

.label-success {
color: #5bc0de;
}

.label-default {
  background: #def5d1;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 10px;
  width: max-content;
  padding: 5px 17px;
  color: #60864a;

}

.label-primary {
color: #337ab7;
}
.text-black{
color:#333;
}
.pagination{float:right;}
.page-link {
color: #353535;
border: 1px solid rgb(222 226 230 / 36%);
border-radius: 4px;
margin: 0px 2px;
}
button:focus{
outline:none;
}
.modal-body{
padding:0rem 2rem 2rem;
}
.lh-10{
line-height:10px;
}
.coverdiv {
z-index: 1000000;
/* background: linear-gradient( 88deg, rgb(233 233 233 / 70%), rgb(255 255 255 / 53%)); */

background: rgba(0,0,0,0.3);
  }
  .modal {
z-index: 10000000;
}
.modal-content{
border-radius:1.4rem;
background: linear-gradient(45deg, #f0ffee, white);
}
.modal-header,.modal-footer{
border: none;
}
.modal-backdrop {
z-index: 10000!important;
}
.modal.show{
  z-index: 10000!important;
}
.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
  justify-content: center;
  margin: 0 auto;
}
ol.breadcrumb{min-height:auto;}
.a-custom{
  color:#333;
}
.a-custom:hover{
  color:#000;
}
#preview-image1{max-width:100%;}
#loadMoreBtn{
  min-width: 174px;
  border: none;
font-size: 1.1rem;
padding: 10px 20px;
border-radius: 7px;
border-radius: 0.375rem;
background: #889eff!important;
border: 1px solid #889eff!important;
}
.mw-300{
  min-width: 300px;
}
.n-title a{
  background: linear-gradient(0deg, #f9f9f9, transparent);
  color:#000!important;
  cursor:pointer;
}
.text-theme-color:focus,.text-theme-color:active{
  outline: none!important;
  border:none;
}
.text-theme-color:hover{
  color:#ff7200!important;
}
.n-title a:hover{
  background: linear-gradient(0deg, #f9f9f9, transparent);
  color:#ff7200!important;
}
.account-toggle:active{
outline:none!important;
border:none!important;
}
.custom-close-modal button{
  width: 30px;
  height: 30px;
}
.max-1300{
  max-width: 1366px!important;
}
.btn-msg-system{
  width: 40px;
    height: 40px;
    float: right;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff7200;
    border-radius: 100px;
    font-size: 20px;
    position: absolute;
    right:0;
    bottom:0;
    cursor: pointer;
    z-index: 10000;
}
.system-msg-container .card-body{
    border-radius: 10px !important;
    background: #ff7200;
    transition-duration: 300ms;
    border-bottom-right-radius: 21px !important;
    width: 300px;
    min-height: 330px;
    display: flex;
    align-self: end;
    overflow: hidden;
    /* opacity: 1; */
}
.system-msg-container .card-body.hide{
  width: 40px;
    height: 40px;
    min-height: 40px;
    padding:0px!important;
    overflow: hidden;
    border-radius: 21px !important;
    /* border-bottom-right-radius: 100px !important; */
    /* min-width: 40px; */
    /* max-width: 40px; */
    /* opacity: 0; */
    transition-duration: 300ms;
}
/* .system-msg-container .card-body .system-msg{
  opacity: 1;
  width:100%;
  height:100%;
} */
/* .system-msg-container .card-body.hide .system-msg{
  opacity: 0;
  width:0;
  height:0;  
} */
.max-left-content{
  max-width: 55%;
}
.mw-100{
  min-width: 100px;
}
.mw-130{
  min-width: 130px;
}
.mw-260{
  min-width: 260px;
}
.mw-220{
  min-width: 230px;
}
.mw-340{
  min-width: 340px;
}
.mw-420{
  max-width: 400px;
  min-width: 400px;
}
.mw-320{
  min-width: 320px;
}
.mw-300{
  min-width: 300px;
}
.mw-280{
  min-width: 280px;
}
.mw-150{
  min-width:150px;
}
.mw-110{
  min-width:110px;
}
.mw-100{
  min-width:100px;
}
.mw-50{
  min-width:50px;
}
.w-55-p{
  width:55%;
}
.mw-200{
  min-width:200px;
}
@media screen and (max-width:767px){
  .mw-260{
    min-width: auto;
  }
  .max-left-content{
    max-width: 100%;
  }
  /* .tc-mobile > div {
    max-width: 65px;
    min-height: 65px;
    display: flex;
} */
  
  .gap-20 {
    gap: 1.5rem;
}
.heading h5{
  font-size: 1rem;
}
  .header-custom-modal .heading{
    width:98%!important;
  }
  .custom-close-modal button{
    width: 30px;
    height: 30px;
    position: absolute;
    top: -10px;
    right: -5px;
    background: white;
    border-radius: 100px;
  }
  .custom-modal{
    left: 10px!important;
    right: 10px!important;
    width: auto!important;
  }
  .account-toggle:after{
display: none!important;
  }
  .dropdown-toggle.notification-dropdown i{
    font-size: 1.1rem!important;
  }
  .logo-container{
    width:30px!important;
    height:30px!important;
  }
  .logo-container img{
    max-width:30px!important;
    height:30px!important;
    border-radius:1px!important;
  }
  .cf{
    margin-top: -28px;
  }
  .scrolled-users{
    /* padding:0px!important; */
    top: 30px!important;
    z-index: 100000000!important;
    height: 90vh!important;
  }
  .left-side-bar{
    height: 100%!important;
    padding-top: 20px!important;
  }
  .gig-table .btn-2,.gig-table .btn-1,.gig-table .btn-3{
padding:5px!important;
margin-left: 4px!important;
  }
  .mw-300{
    min-width: 100%;
  }
  .hp-listing-category__image img {
        display: block;
        width: 27%!important;
        height: auto;
     }
  #loadMoreBtn{
  width: 90%;
  min-width:90%;
  border: none;
font-size: 1.1rem;
padding: 10px 20px;
border-radius: 7px;
border-radius: 0.375rem;
background: #889eff!important;
border: 1px solid #889eff!important;
}
  .modal-open .modal{
     padding:10px;
  }
  .form_lavel_checkbox {
flex-direction: column;
gap: 1rem;
align-items: start;}
  .fsize-meta {
display: block!important;
line-height: 22px;
  }
  .placeholder-thumbnail svg{
     width: 56px!important;
  }
  
  #drop-area{padding:10px;}
  
    .thumbnail-container{
     float: left;
     width: 50px;
     height: 50px;        
     margin: 10px 10px 10px 0px!important;         
     min-width:50px!important;
  }
  .thumbnail-container.tc{    
    margin: 5px 0px 10px 0px!important; 
  }
  .thumbnail-container img{
     width: 50px;
     height: 50px;
  }
  .title-task{
     margin: 7px 0px;
     overflow: hidden;
  }
  .section-space h2{
     font-size:1.9rem!important;
  }
  #pt-8rem {
     padding-top: 6rem!important;
  }
  .section-space{
     padding-bottom:4rem;
  }
  .gig-table .btn-0{
padding: 5px 10px;
background: #f0f0f0!important;
border-radius: 5px;
border-radius: 0.375rem;
margin-left: 10px;
color: #ff7200!important;
}
.tabs_boxs, .task_field, .actions_field{
padding:1rem 0px!important;
}
.task_box {
padding: 1rem;
  }

  
  }

  .hp-listing-category--view-block {
     border-radius: 5px;
     box-shadow: 0 5px 30px rgba(7,36,86,.075);
     background-color: #fff;
     position: relative;
     padding: 2rem 2rem 2.5rem;
     transition: transform .25s;
     height:100%;
  }
  .hp-listing-category--view-block:before {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 2rem;
        left: 2rem;
        display: block;
        height: 5px;
        content: '';
        transition: background-color .25s;
        border-bottom-right-radius: 9999px;
        border-bottom-left-radius: 9999px;
        background-color: rgba(7,36,86,.06);
     }
     .hp-listing-category--view-block .hp-listing-category__header:not(:last-child) {
        margin-bottom: 1rem;
     }
     .hp-listing-category--view-block .hp-listing-category__image img {
        border-radius: 5px;
     }

     .hp-listing-category__image img {
        display: block;
        width: 50%;
        height: auto;
     }
     .hp-listing-category--view-block .hp-listing-category__name:not(:last-child) {
     margin-bottom: 0.75rem;
  }
  .hp-listing-category__name a {
text-decoration: none;
color: inherit;
}
.hp-listing-category__name a:hover {
color: #ff7200;
}
.hp-listing-category--view-block .hp-listing-category__description {
font-size: .875rem;
color: rgba(15,23,39,.45);
}
.hp-listing-category--view-block .hp-listing-category__details--primary:not(:last-child) {
margin-bottom: 0.5rem;
}
.hp-row {
box-sizing: border-box;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-flex: 0;
-ms-flex: 0 1 auto;
flex: 0 1 auto;
-webkit-box-direction: normal;
-ms-flex-direction: row;
flex-direction: row;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-right: -1rem;
margin-left: -1rem;
}
.hp-listing-category--view-block .hp-listing-category__item-count {
font-size: .75rem;
line-height: 1.15;
white-space: nowrap;
position: absolute;
bottom: 0;
left: 50%;
width: 3rem;
margin-left: -1.5rem;
padding: 0.4375rem 0 0.2rem;
text-align: center;
color: rgba(15,23,39,.45);
background-color: rgba(7,36,86,.04);
border-top-left-radius: 17px;
border-top-right-radius: 17px;
}
li.select2-results__option{
/* background-color: #ddd; */
background: linear-gradient(0deg, #f7fcff, #ffffff);
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
background: #ff7200;
color: white;
}
.select2-results {
font-size:1rem;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
border: 1px solid #e8e4e4;
border-radius: 4px;
}
.gap-10{
gap:10px;
}
.select2.select2-container{min-width:250px;}
.select2-container--default .select2-search--dropdown .select2-search__field:focus{
outline:none;
}

.select2-container--default .select2-selection--single{
min-height: 44px;
border: 1px solid #dddddd;
border-radius: 7px;
display: flex;
align-items: center;
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
min-height: 44px;
}
.gig-table a{
  color:#333;
  }
.gig-table .a-hover{
color:rgb(0 156 255 / 80%);
}
.gig-table .a-hover:hover,.gig-table a:hover{
color: #ff7200;
}
.meta span{
  background: #fdfafa;
  padding: 4px 10px;
  border-radius: 7px;

}
.react-datepicker-wrapper {
  width: 100%;
}
.btn-3{
  border:1px solid #ff7200!important;
  margin-left:10px;
  color:#ff7200!important;
}
.btn-3:hover{
  border:1px solid #000!important;
  background-color: #000!important;
  margin-left:10px;
  color:#fff!important;
}
._3bkri{
  margin-top:40px!important;
}
._3AWTh{
  z-index: 10000000000000!important;
}
.css-b62m3t-container{height:44px;}
.css-13cymwt-control{height: 44px;}
.bg-theme-2{
  background-color: #5bd796!important;
}
.dropdown-toggle.notification-dropdown:after {
  display: none;
}
.dropdown-toggle.notification-dropdown i{
  font-size: 1.4rem;
    line-height: 30px;
}
.dropdown-toggle.notification-dropdown .badge{
  color: #ff7200;
    position: absolute;
    right: -4px;
}

.top-banner-part{
  
  width:100%;
  min-height:200px;
  overflow: hidden;
  border-radius: 8px;
  border-radius: 0.375rem;
  background-repeat: no-repeat;
  background-position: center;
  padding: 2.8rem 2rem;
  position: relative;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-repeat: no-repeat!important;
  background-position: center!important;
  
  
}
.cover-black{
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index: 0;
}
.top-banner-part .profile-initial-info{
  position: relative;
  z-index: 1;
}
.top-banner-part .profile-img{
  width:140px;
  height:140px;
  border-radius: 200px;
  border:5px solid white;
  overflow: hidden;
  position:relative;
  z-index: 2;
}
.top-banner-part .profile-img img{
  /* max-width: 150px;
  position:absolute;
  left:50%;
  top:0%;
  transform: translate(-50%, 0%); */
min-height: 140px;
  max-height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.s-icon{
  font-size: 1.4rem;
}
.fb-color{color:#4064AC;}
.insta-color{color:#CA337B;}
.twtr-color{color:#1C9CEA;}
.lnkd-color{color:#0077B0}
.youtube-color{color:red;}
/* .btn-custom-tab{
  flex: 1;
} */
.btn-custom-tab i{
  font-size: 2.5rem;
  color: #ffe0c7;
  margin-bottom: 1rem;
  z-index: 1;
  position: relative;
 
}
.btn-custom-tab img{
  filter: contrast(0.8);
}

.tab-container{
  background: linear-gradient(0deg, #ffffff, #fff7f7);
}
.btn-custom-tab.active i{
  color:#ff7200!important;
  z-index: 1;
  position: relative;
}
.btn-custom-tab.active img{
  opacity: .8;
    filter: drop-shadow(2px 4px 6px #ff7200);
}
.btn-custom-tab.active p{
  color:#ff7200;
}
.btn-custom-tab > div{
  min-height: 51px;
}
.circle-back{
  width: 70px;
    height: 70px;
    position: absolute;
    background: #fbfbfb;
    z-index: 1;
    border-radius: 100px;
    margin-top: -20px;
    z-index: 0;
    margin-left: -20px;
}
.color-gray{
  color:#585858;
}


@media screen and (min-width: 768px){
.video-container .video-item.col-md-4:nth-child(1), .video-container .video-item.col-md-4:nth-child(4), .video-container .video-item.col-md-4:nth-child(7){
  padding-right: 4px;
}
}
.vdo-details {
    top: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(45deg, black, transparent, black);
    left: 0px;
    right: 0px;
    border-radius: 7px;
    color: white;
    cursor: pointer;
}
.video-item .poster-container {
  max-height: 240px;
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
  background-color: black;
}
.vdo-details .lks {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: .8rem;
}
.vdo-details .title {
  bottom: 7px;
  position: absolute;
  line-height: 15px;
  left: 7px;
  right: 7px;
  font-size: 0.9rem;
}
.color-blue-link{
  color:#75defd;
}
.blogs-module .video-item .poster-container {
  height: 140px;
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.9;
  background-color: black;
}
.blogs-module .video-item .poster-container img {
  min-height: 100%;
}
.blog-meta {
  box-shadow: 0px 0px 17px #d3ebff;
  padding: 10px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.meta-info{
  background:linear-gradient(0deg,  #fafcff, transparent)
}

.link-container {
  background: linear-gradient(359deg, #edf6ff, #ffffff);
  padding: 10px;
  border-radius: 7px;
  border-radius: 0.375rem;
}
.link-container-2 {
  background: linear-gradient(359deg, #e5ccf1, #ffffff);
}
.link-container-2 .color-blue-links, .link-container-2 a {
  color: #aa56d1 !important;
  text-decoration: none;
  font-weight: 600;
}
.link-container-2 .color-blue-links, .link-container-2 a {
  color: #aa56d1;
  text-decoration: none;
  font-weight: 600;
}
.implinks a{
  background: white;
    border: 1px solid #ffecdc;
    padding: 2px 10px;
    border-radius: 7px;
    color: #ff7200;
}
.edit_profile_btn{
  position: absolute;
  top:20px;
  right:20px;
  z-index: 2;
}
.edit-pencil-btn{
  background: white;
  color: #ff7200 !important;
  padding: 2px;
  width: 30px;
  height: 30px;
  border-radius: 100px;

  position: absolute;
  margin-top: -30px;
  margin-left: 27px;
  z-index: 2;
}
.premium-link span{
  color:white;
  position:absolute;
  top:20px;
  left:20px;
}
.edit-phone input[type="tel"],.edit-phone .phone-input-container{
  max-width:103px!important;
}
.custom-modal{
  position: fixed;
    z-index: 10000000;   
    box-shadow: 0px -10px 30px rgba(0,0,0,0.3);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 900px;
   
}
.custom-modal .body-custom-modal{
  max-height: 77vh;
  /* max-height: 400px; */
  overflow-y:auto;
}
.custom-modal.show{
  transition-duration: 300ms;
  /* bottom: 0; */

  bottom: 20px;
    top: 20px;
    border-radius: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.custom-modal.hide{
  transition-duration: 300ms;
  bottom: -100%;
  top: 100%;
}
.custom-modal-backdrop.show{
  transition-duration: 300ms;
  display: block;
  opacity: 1;
}
.custom-modal-backdrop.hide{
  transition-duration: 300ms;
  display: none;
  opacity: 0;
}
.credit-btn-default{
  background: #f6dbc5 !important;
  color: #ff7200 !important;
  border-radius: 0.375rem;
}
button#inbox-tab-tab-notifications, button#inbox-tab-tab-messages{
    background-color: #f0f0f0;
    color:#585858;
}
#inbox-modal button.nav-link.active{
  background-color: #ff7200;
  color:white
}
#inbox-modal ul.nav-pills{
  max-width: 600px;
  margin:0 auto;
  gap:10px;
}
.content-tab .users-list-item:nth-child(odd){
  background-color: #f9f9f9;  
}
/* .offer-btn{
    position: relative;
    display: inline-block;
    background-color: #ff9900;
    overflow: hidden;
}
.offer-btn:before {
    content: '';
    position: absolute;
    top: -110%;
    left: -50%;
    width: 200%;
    height: 160%;
    border-radius: 600%;
    z-index: 0;
    background: rgba(255, 255, 255, 0.4);

} */

.offer-btn{
  /* background-image: linear-gradient(to right, #3bcbaf 0%, #F4D03F  51%, #3bcbaf  100%)!important; 
  transition: 0.5s;
  background-size: 200% auto; */
  box-shadow: 0px 3px 3px #58e68b inset, 0px -3px 3px #58e68b inset;
    text-shadow: 0px 1px 5px rgb(51 51 51 / 31%);
    background: #3ecb89 !important;
  border-radius: 0.375rem;
  border:none;
}

.offer-btn:hover {
  color:white!important;
  background: #2ba86d !important;
}
.scrolled-users .offer-container{
  position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.metas-offers{
  background: #f9f9f9;
    padding: 1rem;
    border-radius: .375rem;
}
.custom-modal-backdrop{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    filter: blur(1px);
}
.css-13cymwt-control{
  height: 40px;
    /* margin-top: 11px; */
}
.load-more-custom{
  background: #6da5f6 !important;
    border: none;
    height: 44px;
}
.load-more-custom.btn-secondary{
  background: #aacbf9 !important;
    border: none;
    height: 44px;
}
.survey-card-header{
    background: #e9e9ff!important;
    color: #307ea7!important;
    border: none;

}
.survey-btn{
  box-shadow: 0px 3px 3px #40a3dc inset, 0px -3px 3px #40a3dc inset;
  text-shadow: 0px 1px 5px rgb(51 51 51 / 31%);
  background: #62b5e1 !important;
  border-radius: 0.375rem;
  border: none;
  margin-left: 15px;
 }
 .new-text {
  color: red;
  animation: blink 500ms infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


   /* custom tab styling */
   .custom-tabs {
    display: flex;
  position: relative;
  width: 100%;
  max-width: 500px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  background: #e7e7ff78;
  box-shadow: 0px 0px 8px #ceceeb inset;
  }

  .tab-item {
    padding: 10px 20px;
    flex: 1;
    cursor: pointer;
    border: none;
    border-bottom: none;
    text-transform: uppercase;
    font-weight: 600;
    color: #ff7200;
    z-index: 1;
  }

  .tab-item.active {
  color: #fff;
  }

  .custom-tabs .custom-tab-indicator {
      position: absolute;
      bottom: 5px;
      left: 5px;
      height: 80%;
      background-color: #ff7200;
      border-radius: 8px;
      z-index: 0;
      transition: left 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  .custom-tabs-contents .custom-tab-content {
      display: none;
  }

  .custom-tabs-contents .custom-tab-content.active {
      display: block;
  }
  
  .hover-theme{
    color:#000!important;
  }
  .hover-theme:hover, .color-blue-hover:hover{
    color:#ff7200!important;
  }

  .img-container{
    width:60px;
    height:60px;
    overflow: hidden;
    position: relative;    
  }
  .img-container img{
    max-width: 90px;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
  }
  .pr{
    color: rgb(0 156 255 / 80%)!important;
    margin-left: 6px;
}
.big-dots{
  width: 4px;
    height: 4px;
    display: inline-block;
    background: #dedada;
    border-radius: 100px;
    margin: 0px 5px;
}
.noti-load-more{
  position: absolute;
    bottom: 11px;
    margin: 0 auto;
    left: 10px;
    right: 10px;
    max-width: 200px;
}
.desc-camp p{
  margin-bottom:0px;
}
.imgtint {
  /* filter: sepia(100%) hue-rotate(90deg); */
}
.custom-dropdown{
  /* background: #e0e9e5!important; */
  border: 1px solid #c6d4ce52!important;
}
.color-444{
  color:#494743;
}
.mid-sec{
  min-width: 100px;
}
.theme-a{
  color:#333;
}
.theme-a:hover{
  color:#ff7200;
}
.theme-a-light{
  color:rgba(0, 0, 0, 0.5) !important
}
.theme-a-light:hover{
  color:#ff7200!important;
}
.edit-btn{
  box-shadow: none;
  background: #ffffff !important;
  color: #5e5e5e !important;
  border: 1px solid #c6d4ce52 !important;
  text-shadow: none !important;
  min-width: 40px;
  color: #3ec887 !important;
  border: 1px solid #99ebbd !important;
}
.edit-btn:hover{
  border-color:rgba(255, 115, 0, 0.611)!important;
  background-color: white!important;
}
.cancel-btn{
  box-shadow: none;
  background: #ffffff !important;
  color: #d38787 !important;
  border: 1px solid rgba(212, 198, 198, 0.322) !important;
  text-shadow: none !important;
  min-width: 40px;
  
}
.cancel-btn:hover{
  background-color: white!important;
  color: #c83e3e !important;
  border: 1px solid #c83e3e !important;
}
.pause-btn{
  box-shadow: none;
  background: #ffffff !important;
  color: #5e5e5e !important;
  border: 1px solid #c6d4ce52 !important;
  text-shadow: none !important;
  min-width: 40px;
  color: #ffc107 !important;
  border: 1px solid #ffc107 !important;
}
.opacity-6{
  opacity: .6;
}
.pause-btn:hover{
  border-color:rgba(255, 115, 0, 0.611)!important;
  background-color: white!important;
  color:#5e5e5e!important;
}
.slide-items-plans{
  padding:1rem;
  cursor: pointer;
}
.circle-plan{
  width: 80px;
  height: 80px;
  background: linear-gradient(45deg, #f6f6f6, transparent);
  border-radius: 100px;
  position: absolute;
  top: 50px;
}
.plans-promotion-slide{
 
  padding: 4rem 1rem 2rem 1rem;
  /* background: linear-gradient(45deg, #f9eddf, #ffe073); */
  gap: 10px;
  border: 10px solid #fff;
  border-radius: 20px;
  overflow: hidden;  
  border: 1px solid #e4e4e4;
  
}
.prev-b-span{
  font-size: 1.1rem;
  color: #ccc;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.prev-b-span:hover{
  color:#ff7200;
}
.nxt-prev{
  position: absolute;
  bottom: 30px;
  right: 25px;
  display: flex;
  left:25px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px -20px 20px #fff;
  min-height: 60px;
}
.circle-head{
  position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #f0f0f0;
    z-index: 1;
    right: 0;
    border-radius: 100px;
    background: white;
}
.heading-main h5{
    font-size: 1.1rem;
    background: white;
    z-index: 1;
    position: relative;
    width: max-content;
}
.heading-main h6{
  /* font-size: 1rem; */
  background: white;
  z-index: 1;
  position: relative;
  /* width: max-content; */
  line-height: 24px;
}
label.active{
  border-color:#ff7200;
}
.heading-hr{
  /* width: 40px;
  border: 2px solid #ff7200;
  margin: 14px auto 20px auto;
  transition-duration: 500ms; */
  position: absolute;
  top: -5px;
  width: 100%;
  border-color: #ccc;
}
.heading-main:hover .heading-hr{
  /* width: 60px;  
  transition-duration: 500ms; */
}
.nxt-plan-step{
    font-size: 2rem!important;
    width: 60px;
    height: 60px;
    border-radius: 100px!important;   
}

.plans-promotion-slide img{
    display: block;
    width: 100px;
    opacity: 0.8;
}
.edit-btn:hover{
  color: #5e5e5e !important;
}
.custom-radio{
  width: 30px;
    height: 30px;
    text-align: center;
    /* background: linear-gradient(45deg, #dfdfdf, transparent); */
    border-radius: 100px;
    font-size: 0.9rem;
    color: #ff7200;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ccc;
}
input[name='planTypeChoose']:checked + div.custom-radio, input[name='planChoose']:checked + div.custom-radio, input[name='subPlanChoose']:checked + div.custom-radio{
  width: 30px;
    height: 30px;
    text-align: center;
    /* background: linear-gradient(45deg, #dfdfdf, transparent); */
    border-radius: 100px;
    font-size: 0.9rem;
    color: #ff7200;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid;
}
input[name='planTypeChoose']:checked + div.custom-radio i, input[name='planChoose']:checked + div.custom-radio i, input[name='subPlanChoose']:checked + div.custom-radio i{
  opacity: 1!important;
}
.custom-radio-container{
  width:30px;
  height:30px;
  margin:0 auto;
  margin-top: 2rem;
}
input[name='planTypeChoose'], input[name='planChoose'], input[name='subPlanChoose']{
  width:30px;
  height:30px;
  position:absolute;
  top:0;
  left:0;
  opacity: 0;
  z-index: 1;
}
#popover-basic{
  box-shadow: 0px 6px 16px rgb(0 0 0 / 20%);
    border-radius: 20px;
    border:none;
    border-top: 5px solid #dfdfdf;
}

.rate-btn{
  box-shadow: none;
  background: #ffffff !important;
  color: #5e5e5e !important;
  border: 1px solid #c6d4ce52 !important;
  text-shadow: none !important;
  min-width: 40px;
  color: #ff7200 !important;
  border: 1px solid #ebc899 !important;
}
.rate-btn:hover{
  color: #5e5e5e !important;
}
.fsize-p8{
  font-size: .8rem;
}
.paused{
  border-left: 3px solid #e1bf1f !important;
}
.running{
  border-left: 3px solid #51df8b!important;
}
.approved{
  border-left: 3px solid #585858!important;
}
.inactive{
  border-left: 3px solid #c03838!important;
}
.react-datepicker__input-container{
  -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    height: 40px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    overflow: hidden;
}
.react-datepicker__input-container input{
  width: 100%;
  height: 100%;
  padding:0px 10px;
}
.react-datepicker__close-icon:after {
  font-size: 21px!important;
  background: none!important;
  color: #cccccc!important;
  font-weight: 600!important
}
.remove-filter{
  opacity: .5;
  cursor:pointer;
}
.heart{
  color: #cccbcb;
  font-size: 1.2rem;
}
.heart.active{
  color: rgb(218, 47, 47)!important;
  font-size: 1.2rem;
}
.dispute-btn{
    box-shadow: 0px 3px 3px #e65858 inset, 0px -3px 3px #e65858 inset;
    text-shadow: 0px 1px 5px rgb(51 51 51 / 31%);
    background: #dc3545 !important;
}
.dispute-btn:hover{
  background: #d02c3c !important;
}
.shadow-custom{
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 9%) !important;
}
.btn-like-circular{
  border: 1px solid #ccc!important;
    width: 35px;
    height: 35px;
    border-radius: 50%!important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
}
.btn-like-circular:hover{
  border-color:rgb(218, 47, 47)!important;
}
.review-container{
  background: #f0f0f0;
  padding: 1rem;
  border-radius: 7px;
  margin-bottom: 1rem;
}

.logo-plan-type{
  width: 40px!important;
}
.highlighted{
  background: #f0f6ff !important;
  border: 3px solid #80acee !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
}
.featured{
  background: #3ebf7a;
    color: white;
    padding: 9px 8px;
    font-size: 0.7rem;
    font-family: arial;
    letter-spacing: 1px;
    border-radius: 10px;
    line-height: 0px;
}
.highlighted h6 a strong{
  color: #3f669f;
}
.highlighted h6 a strong:hover{
  color: #ff7200;
}
.add-credit-btn{
  padding-left: 7px!important;
  position:relative;
}
.add-credit-btn i.fa-plus{
  position: absolute;
  font-size: 0.7rem;
  right: 8px;
  top: 4px;
  background: white;
  border-radius: 20px;
  padding: 1px;

}
.pauseedit{
  background: rgba(0, 0, 0, 0.4);
  padding: 0px 7px;
  display: flex;
  border-radius: 100px;
  width: 60px;
  justify-content: space-between;
  position: absolute;
  color: white;
  bottom: 4px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 0.8rem;
}
.play-pause-btn{
    padding: 0 !important;
    background: none !important;
    border: none !important;
    flex: 1;
    min-width: auto !important;
    border-radius: 0px;
    align-items: center;
    display: flex;
}
.play-pause-btn.play{  
  /* border-right: 1px solid #ffffff42 !important; */
}
.play-pause-btn.play i{
  color: pink!important;
}
.play-pause-btn:hover, .edit-small-btn:hover{
  background: none!important;

}
.play-pause-btn:hover i, .edit-small-btn:hover i{
  color:white!important;
  
}
.edit-small-btn{
    padding: 0 !important;
    background: none !important;
    border: none !important;
    min-width: auto !important;
    flex: 1;    
    border-left: 1px solid #ffffff42 !important;
}
.pauseedit i{
  flex:1;
  text-align: center;
}

.slidUpDown {
  height: 0;
  overflow: hidden;
  transition: height 4s ease-in-out, opacity 4s ease-in-out;
  opacity: 0;
  padding:0!important;
}

.slidUpDown.show {
  height: auto;
  opacity: 1;
  transition: height 4s ease-in-out, opacity 4s ease-in-out;
}

.title-review{
  border: 1px solid #ebe5e5;
  border: 1px solid #f5f5f5;
  padding: 2.1rem;
  padding: 1rem 1rem;

  border-radius: 10px;
  /* border-bottom: 4px solid #f0f0f0; */
  background: #fdfdfd;
}
.rtask{
  /* margin: 10px; */
    border: 1px solid #f4f2f2;
    border-radius: 10px;
    border-bottom: 4px solid #f4f2f2;
}
.bg-gray{
  background-color: #fdfdfd;
}
.employer-fav-end{
  background: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 4px 10px;
  border-radius: 7px;
}
.stick-actions{
  position: sticky;
    top: 73px;
    box-shadow: 0px 10px 1px rgb(248 248 247);
}
.dot-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between dots */
}

.dot {
  width: 9px;
  height: 9px;
  background-color: #f66464;
  border-radius: 50%;
  animation: blink 1s infinite;
  position: absolute;
  top: 0px;
  right: 3px;
  border: 2px solid #fff;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.dot:nth-child(4) {
  animation-delay: 0.6s;
}

.dot:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes blink {
  0%, 100% {
      opacity: 1;
  }
  50% {
      opacity: 0;
  }
}
label[for="allCountry"]{
  margin: 0;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    padding: 0.1rem 1rem;
    height: 41px;
    border-radius: 7px;
    /* position: absolute;
    right: 15px;
    top: 28px; */
    gap: 6px;
 }
 .react-joyride__overlay{
  z-index: 10000;
 }
 form input.border, form .css-13cymwt-control, form .tox .tox-edit-area__iframe, form textarea, form select{
  background: rgb(249 249 249 / 19%);
  color: #464646;
 }
 .heading-main-2 h5{
  font-size: 1rem;
  color: #878b95;
  /* color: #cbcbcb; */
  background-color: #f8f8f7 !important;
 }
 .see-more-container{
  display: flex;
  align-items: center;
  gap: 10px;
  /* background: #ffffff; */
  cursor: pointer;
  background: rgb(255 255 255 / 25%);
  padding: 4px;
  border-radius: 10px;
  color: #858282;
 }
 .see-more-container i{
  width: 30px;
    height: 30px;
   background: rgb(240 240 240 / 35%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
 }
 .common-tg-bg{
  padding: 5px 10px!important;
    /* min-width: 0px; */
    text-align: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 7px;
    flex: 1;
    min-width: max-content;
 }
 .max-50{
  max-width: 50%;
 }
 .dollar-price{
  color: #29ae6e;
    font-size: .95rem;
 }
 .form-control{
  appearance: auto!important;
  min-height: 40px;
 }
 .form-control:focus{
  box-shadow: none!important;
 }
 .color-light-gray{
  color:#a5a5a5!important;
 }
 .share-q{
  top: -10px;
  right: 24px!important;
 }
 .custom-loader-small{
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-left: 0.6rem;
 }
 .email-verify-cover{
  position: fixed;
    background: rgb(255 255 255 / 95%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
 }
 .verify-email-container {
  transition: min-height 2s; /* Apply transition to the base class */
}
 .verify-email-container.expand{
  min-height: 90px;
 }
 .verify-email-container.noexpand{
  min-height: 45px;
 }
 .close-verify-email-cover-btn{
  position:fixed;
  cursor: pointer;
  right: 20px;
  top:20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbae6f;
  border-radius: 50px;
  color: white;
 }
 .video-container {
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  /* background-color: #f9f9f9; */
}

.img-cont span > span:first{
  padding:50% 0 0!important;
}
.play-pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  /* padding: 10px; */
  cursor: pointer;
  z-index: 10;
  border-radius: 100px;
  width: 80px;
  height: 80px;
  font-size: 2rem;

}

.seekbar-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 4px; /* Adjust height as needed */
  background-color: #ced4da; /* Seekbar background color */
}

.seekbar {
  width: 100%;
  height: 100%;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  position:absolute;

}

.seekbar::-webkit-slider-runnable-track {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(232, 27, 27) 0%, rgb(232, 27, 27) calc(var(--seekbar-value) * 100%), #ced4da calc(var(--seekbar-value) * 100%), #ced4da 100%);
  border-radius: 5px;
}

.seekbar::-webkit-slider-thumb {
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: rgb(232, 27, 27); /* Seekbar thumb color */
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  margin-top: -4px;
  
}

.seekbar-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: calc(var(--seekbar-value) * 100%);
  background-color: rgb(232, 27, 27); /* Color of the covered seekbar */
  z-index: 0;
}

.seekbar-container::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: calc(100% - (var(--seekbar-value) * 100%));
  background-color: #ced4da; /* Color of the uncovered seekbar */
  z-index: 0;
}
.hover-c-gray{
  color:rgba(0,0,0,0.5)!important
}
.hover-c-gray:hover{
  color:#ff7200!important;
}
.theme-hover-color{
  color:#363a43;
}
.blur-c{  
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}
.theme-hover-gray{
  color:#ccc;
}
.theme-hover-color:hover, .theme-hover-gray:hover{
  color:#ff7200;
}
#replybtns{right: 0 !important;}
.indicators {
  max-width: 240px;
  opacity: 0.6;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: fixed;
  bottom: 100px;
  animation: moveUpDown 1s infinite;
}
@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.flex-1{
  flex:1;
}
#custom-head-transparent{
  background-color: transparent!important;
}
.tabs_main_box .container > .row{
    gap:0.4rem;
}
.tabs_main_box .container > .row .col{
  padding:0px;
}
.tabs_main_box .container .box{
  padding: 1rem 0.5rem;
}
.tabs_main_box .box_title{
  font-size: 11px!important;
}
.f-small{font-size: .775em!important;}
.scrolled-user{
  direction: rtl;
}
.scrolled-user > div{
  direction: ltr;
  margin-left: 1rem;
}
h6 strong.d-flex.flex-columns{
  line-height: 13px;
  margin-top: 2px;
}
.title-left-icon{
  color: #ff7200;
    font-size: 0.8rem;
    font-weight: bolder;
    letter-spacing: -2px;
}
.share-custom{
  top:10px;
}
.MathJax{
  text-align: left!important;
}


/* css for careernews */
.card_new {
  background: #FAF6F3;
  border-left: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-top: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 10px 10px 10px 10px;
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
}

.community-container .card-body {
  padding: .05rem;
}

.feature_images_box {
  display: flex;
  justify-content: start;
}

.featured_subject_icon .feature_images_box img {
  border-radius: 5px;
  width: 80px;
  height: 80px;
  padding: 0.7rem;
}

.space_content {
  padding: 0 15px 15px;
}

.card_new .card-body .space_content a .theme-orange-color {
  color: #333;
  font-size: 18px;
}

.space_content .sub-title {
  line-height: 22px;
  margin-bottom: 0.6rem !important;
}

.top_beginner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_beginner p, .question_para {
  margin-bottom: 0.2rem;
}

/* .top_beginner p span, .card_new .space_content p span {
  font-size: 16px;
} */

.card_new .space_content p span .fa-angles-right {
  color: #ff7200;
  margin-right: 10px;
}

.top_beginner p, .question_para {
  margin-bottom: 0.2rem;
}

.right_arrow {
  display: flex;
  justify-content: end;
  margin-bottom: 0px;
}

.latest_question_title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem !important;
}

.main-titles {
  color: #161414;
  text-align: left;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 121%;
}
.latest_question_title .sub-title {
  color: #383838;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.color-orange, .right_arrow:hover span, .community-container:hover {
  color: #ff7200;
}

.right_arrow span {
  color: #333;
  font-size: 16px;
}

.card_box:hover, .card_box:focus, .card:hover, .card:focus {
  box-shadow: 0 0 20px #ccc !important;
  transition: all 0.5s ease;
}
.card_new:hover h4, .card_new:hover .right_arrow span {
  color: #ff7200!important;
}

a.card-header-bar.btn, button.loadButton {
  /* background: #ffe4d3!important; */
  border-radius: 5px;
  font-weight: bold!important;
  /* color: #80370A!important; */
  height:57px!important;
}


button.card-btn-2{
  height:57px!important;
  color: #80370A!important;
  font-weight: bold!important;
}
.card-btn-2 a, .card-btn-2 span {
  color: #f08645;
}

.card_box {
  background-color: #FAF6F3;
  border-right: 0px solid #B774FA;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  /* padding: 10px !important; */
  border-bottom: 0px solid #b774fa;
  display: flex;
  align-items: center;
  padding: 0px 5px !important;
}
.mh-119 {
  min-height: 100px;
}

.card_box:hover, .card_box:focus, .card:hover, .card:focus {
  box-shadow: rgba(255, 114, 0, 0.5) 1px 1px 8px 1px;
  transition: all 0.5s ease;
}

.books_icon {
  background-color: #FFE8D6;
  padding: 10px 15px;
  border-radius: 10px;
}

.lquestion {
  color: #161414;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
}

a:hover h6 span.lquestion,.hover-c-change:hover h6.lquestion {
  color: #ff7200 !important;
}

.blink_me {
  animation: blinker 500ms linear infinite;
  color: red;
  font-weight: bold;
}



@keyframes blinker {
  60% {
      opacity: 0.2;
  }
}

.mh-93{
  min-height:93px;
}
.mh-95{
  min-height: 95px;
}
.fsize-18{
  font-size: 18px;
}
.fsize-17{
  font-size: 17px;
}
.c-a5{
  color:#a5a5a5;
}

.card-header-bar {
  padding: 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* box-shadow: 0px 0px 4px #f0f0f0; */
}
.header2 h5, .header3 h5, .header4 h5, .header5 h5, .header6 h5, .header7 h5, .header8 h5, .header9 h5, .header10 h5, .header11 h5, .header12 h5, .header13 h5 {
  font-weight: 600;
  font-size: 18px;
}
.header1 {
  background: #fafbfc;
  border-bottom: 4px solid #2879F5;
}

.header2 {
  border-bottom: 4px solid #f08645;
  background: #fafbfc;
}

.header3 {
  background: #fafbfc;
  border-bottom: 4px solid #3e9f48;
}


.color-1 {
  color: #f08645;
}

.color-2 {
  color: #3e9f48;
}

.color-3 {
  color: #2879f3;
}

.color-4 {
  color: #913ed0;
}

.color-5 {
  color: #6862c5;
}

.color-6 {
  color: #f7b928;
}

.color-7 {
  color: #f35454;
}

.color-8 {
  color: #56cc6f;
}

.color-9 {
  color: #d582ec;
}

.header4 {
  background-color: #fafbfc;
  border-bottom: 4px solid #b645f0;
}


.card-btn-1 {
  background-color: #deeefe!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-1 a, .card-btn-1 span {
  color: #2879f3;
}

.card-btn-2 {
  background-color: #ffe4d3!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-2 a, .card-btn-2 span {
  color: #80370A;
}

.card-btn-3 {
  background-color: #d8ffe0!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-3 a, .card-btn-3 span {
  color: #3e9f48;
}

.card-btn-4 {
  background-color: #eed8f9!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-4 a, .card-btn-4 span {
  color: #b645f0;
}

.header5 {
  background-color: #fafbfc;
  border-bottom: 4px solid #f045ac;
}

.card-btn-5 {
  background-color: #ffe5f2!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-5 a, .card-btn-5 span {
  color: #f045ac;
}

.header6 {
  background-color: #fafbfc;
  border-bottom: 4px solid #21b19e;
}

.card-btn-6 {
  background-color: #bffbf3!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-6 a, .card-btn-6 span {
  color: #21b19e;
}

.header7 {
  background-color: #fafbfc;
  border-bottom: 4px solid #a3ad4b;
}

.card-btn-7 {
  background-color: #eef5b0!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-7 a, .card-btn-7 span {
  color: #a3ad4b;
}

.header8 {
  background-color: #fafbfc;
  border-bottom: 4px solid #e0542b;
}

.card-btn-8 {
  background-color: #ffd9ce!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-8 a, .card-btn-8 span {
  color: #e0542b;
}

.header9 {
  background-color: #fafbfc;
  border-bottom: 4px solid #995cea;
}

.card-btn-9 {
  background-color: #e7d8fb!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-9 a, .card-btn-9 span {
  color: #995cea;
}

.header10 {
  background-color: #fafbfc;
  border-bottom: 4px solid #cdb332;
}

.card-btn-10 {
  background-color: #ffeea9!important;
  border-radius: 5px;
  font-weight: bold;
}

.btn:hover span {
  color: #212529!important;
}

.card-btn-10 a, .card-btn-10 span {
  color: #cdb332;
}

.header11 {
  background-color: #fafbfc;
  border-bottom: 4px solid #adb903;
}

.card-btn-11 {
  background-color: #f8ffc0!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-11 a, .card-btn-11 span {
  color: #adb903;
}

.header12 {
  background-color: #fafbfc;
  border-bottom: 4px solid #2fb1c5;
}

.card-btn-12 {
  background-color: #c8f7ff!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-12 a, .card-btn-12 span {
  color: #2fb1c5;
}

.header13 {
  background-color: #fafbfc;
  border-bottom: 4px solid #d6a470;
}

.card-btn-13 {
  background-color: #ffecd8!important;
  border-radius: 5px;
  font-weight: bold;
}

.card-btn-13 a, .card-btn-13 span {
  color: #d6a470;
}
.hover-div:hover h6 span{
  color:#ff7200!important;
}
.hover-div:hover{
  background: #fff4e8!important;
  border-color: #fbe2c7!important;
}
.loadButton:focus,.loadButton:hover, .loadButton:active{border: none!important;}
.load-more-span{
  width:30px;
  height:30px;  
  display: block;
  margin:0 auto;
}
.right-side-links{
    padding: 0.6rem 0rem;
    display: block;
    color:#585858;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.right-side-links:hover, .right-side-links.active{
  color:#ff7200;
}

.reply-class{
  background: white;
  margin: 15px 0px 0px 0px;
  padding: 0px 10px;
  border-radius: 7px;

}

.cn-desc table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  margin-top: 20px;
  
}
.cn-desc table > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.cn-desc table tbody tr td {
  /* color: #8E8E8E; */
  font-family: Droid Serif;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 5px;
  border:1px solid #f0f0f0;
}
.cn-desc table tbody tr td p{
  margin:0px;
}
.cn-desc h2{
  /* margin-top:2rem; */
      font-size: 1.2rem;
    font-weight: 700;
}
.cn-desc ul{
  padding-left:0;
  
}
.cn-desc ol{
  padding-left:15px;
  list-style: auto;
}
.cn-desc h3.lquestion::before{
  content: 'Q';
  font-weight: 600;
  position: absolute;
  left: -10px;
  color: #c7e3ff;
  font-size: 1.5rem;
  margin-top: -4px;
}
.cn-desc h3.lquestion{
  text-align:left;
  position:relative;
  padding-left:15px;
}
.cn-desc h4{
  font-size: 1rem;
}
.cn-desc table {
  display: table;
}
.cn-desc .collapse{
  visibility: visible;
}

.qThumb{
  min-width: 320px;
}
.opacity-05{
  opacity: 0.7;
}
.lh-1p6{
  line-height: 1.6;
}

@media (min-width: 1024px) and (max-width: 1500px) {
  .grid-cols-4 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.tabbed-circle {
  box-shadow: 0px 0px 1px #0000003b inset;
  padding: 5px 10px;
  border-radius: 0.9rem;
}
/* .otherPlatformLink{
  padding:0px!important;
  margin:0px!important;
}
.otherPlatformLink > div{
  flex:1;
  /* background: rgb(255 114 0 / 11%); 
    padding: 8px 5px !important;
    border-radius: 6px;
}
.otherPlatformLink > div:hover{
  background: rgb(255 114 0 / 11%);
    padding: 8px 5px !important;
    border-radius: 6px;
} */
.cn-desc h3{
  font-size: 1.1rem;
  font-weight: bold;
}
.bottom-line-orange{
  height:1px;
  background-color: #ff7200;
  width: 30px;
  position: absolute;
  top:0
}
ol li strong{
  width: 100%;
  display: block;
  margin-top: 10px;

}
.star-container > span{
  background: #54b977;
  color: white;
  padding: 1px 6px;
  border-radius: 3px;
  display:inline-flex;
  gap:2px;
  align-items: center;
  font-weight: bold;
  line-height: 18px;
}
.claim-acc-btn{
  background-color: transparent!important;
}
.claim-acc-btn:hover{
  color: #585858!important;
}
.claim-acc-btn span{
  color:#ff7200!important;
}
.cn-desc table tbody tr td{
  border: 1px solid #c1dea0;
}
.cn-desc .table tbody tr td {
  color: #343434;
  padding:10px;
  line-height: inherit;
}
.tab-custom .nav-link {
  padding: 8px 20px !important;
  background-color: #f9f9f9;
  margin-right: 10px;
  color:#585858;
}
.gallery-img-container{
  width: 100%;
    height: 100px;
    overflow: hidden;
    background: #f0f0f0;
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}
span.gallery-location {
  position: absolute;
  background: linear-gradient(0deg, black, transparent);
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 10px;
}
.span-answer-container{
  background-color: transparent;
  position:relative;
   border:1px solid #f0f0f0;
  border-radius: 7px; 
  margin-top: 10px;
  padding: 1rem;
}
.vote-upvote-answer-container{
  background: #fff;
  border: 1px solid #f0f0f0;
  font-size: 0.8rem;
  border-radius: 7px;
}
.vote-upvote-answer-container > span{
  padding:5px 15px;
  cursor:pointer;
}
.vote-upvote-answer-container > span:hover{
  background-color: #f0f0f0;
}
.vote-upvote-answer-container > span:first-of-type{
  border-right:1px solid #f0f0f0;
}
.bt{border-top: 1px  solid #f0f0f0!important;}
.span-answer *{
  background:none!important;
}
h6 strong p{
  margin:0px!important;
}
.btn-plus{
  border-radius: 50px;
    background: white;
    border: 1px solid #ff7200;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ff7200;
    width: 30px;
    height: 30px;
    padding: 0px;
    z-index: 1;
}
.btn-minus{
    border-radius: 50px;
    background: white;
    border: 1px solid #f0f0f0;
    position: absolute;
    right: 10px;
    top: 10px;
   
    width: 30px;
    height: 30px;
    padding: 0px;
}
.filter-select{
    padding: 8px;
    border-radius: 5px;
    border: 1px solid rgb(239 229 229);
    background: rgb(255 255 255);
}
.c--table th, .c--table td{
padding:10px!important;
}
/* General timeline styling */
.timeline {
  list-style-type: none;
  padding: 20px 0;
  position: relative;
}

/* Styling for each list item */
.timeline li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
}

.timeline li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #ffb77d; /* Green left progress color */
}

/* Styling for odd and even items to create distinction */
.timeline li:nth-child(odd) {
  background-color: #fbf8f8;
}

.timeline li:nth-child(even) {
  background-color: #fbf8f8;
}

.timeline li:last-child {
  margin-bottom: 0;
}
.thumb-trans{
  background: #ffb68522;
    width: 40px;
    height: 40px;
    color: #ffa55b;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 100px;
}
.collapse-bar{
  background: hsl(0deg 0% 100% / 46%);
    padding: 0.8rem 1rem;
    border-radius: 10px 10px 0px 0px;
}
.collapse-btn{
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color:rgb(245 245 245);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7a7a7a;
}
.bell-icon {
  display: inline-block;
  position: absolute;
  font-size: 1.2rem; /* Adjust size */
  color: #ff9800; /* Customize bell color */
  cursor: pointer;
  right: 5px;
  top: -10px;
}

.bell-icon i {
  animation: shake 1.5s infinite; /* Infinite shaking */
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(-15deg); }
  30% { transform: rotate(15deg); }
  45% { transform: rotate(-10deg); }
  60% { transform: rotate(10deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
.small-btn{
  background: rgb(204 204 204 / 10%);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem !important;
  color: #ff7200e0;
  min-width: 80px;
  margin-top:.75rem;
}
.stats-my {
  box-shadow: 0px 0px 8px rgb(0 0 0 / 8%);
  border-radius: 4px;
  padding: 0.6rem 0.2rem;
}

.ql-table {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.ql-table td {
  border: 1px solid #ddd;
  padding: 5px;
}
.faq-item ul{
  list-style: circle !important;
}

fieldset {
  border: 2px solid #4CAF50;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

/* Styling for the legend */
legend {
  font-size: 1.1rem!important;
  color: #dca15d!important;
  font-weight: bold;
}
.message-container{
  position: absolute;
    z-index: 1;
    padding: 1rem;
    text-align: center;
    top: 30%;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 0%);
    left: 20px;
    right: 20px;
    border-radius: 10px;
    max-width: 320px;
    margin: 0 auto;
    background: rgb(255 255 255 / 58%);
    font-size: 0.8rem;
}
.btn--light{
  /* max-width: 280px; */
    font-size: 0.8rem!important;
    margin: 0 auto;
    min-height:40px;
    width:100%;
}
/* Styling for paragraphs inside the fieldset */
fieldset p {
  font-size: 1.1em;
  color: #333;
}

fieldset p strong {
  color: #4CAF50;
}
.credit-btn{
  opacity:0.8;
}
.btn-action{
  min-width: 160px;
  background-color: transparent;
  border:1px solid #ccc;
  color:#ccc;
}
.c--table.c2 td{
  align-items: center;
  vertical-align: middle;
  padding: 6px 10px !important;
}
.btn-action{
  min-width: 160px;
  background-color: transparent;
  border:1px solid #ff7200;
  color:#ff7200;
}
.report-msg-box{
  min-height: 200px;
}
.scrollable-container {
  overflow-x: auto;
  overflow-y: hidden; /* Optional for vertical scrolling */
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  position: relative;
}

/* WebKit-based browsers (Chrome, Safari, newer Edge) */
.scrollable-container::-webkit-scrollbar {
  height: 3px; /* Default scrollbar height */
  background: transparent; /* Transparent background */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #ccc; /* Default scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

/* Hover Effect */
.scrollable-container:hover::-webkit-scrollbar {
  height: 3px; /* Reduced height on hover */
}

.scrollable-container:hover::-webkit-scrollbar-thumb {
  background: #888; /* Darker thumb color on hover */
}
.statstistics{
  border: 1px solid #f0f0f0;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}
.c-table th, .c-table td{
font-size: .8rem!important;
text-transform: capitalize;
}
button:active{
border: none;
}
#editorContent {
  max-width: 100%; /* Prevent exceeding the container */
  box-sizing: border-box; /* Include padding and border in width calculation */
  overflow: hidden; /* Hide overflow content */
}
.sticky-head.start-exam-main,.start-exam-main .left-side-bar{
  display:none!important;
}
.start-exam-main .middle-content{
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%!important;
}
.searched-title pre, .searched-title code{
    color: #d63384;
    word-wrap: break-word;
    white-space: normal;
    margin-bottom: 0;
}
.tooltip-search{
    position: absolute;
    left: 20%;
    bottom: -20px;
    background: #fff;
    padding: 0.4rem 1rem;
    border-radius: 100px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 8%);
    color: #585858;
    display: none;
}
.tooltip-search::before{
    content: '';
    border-top: 0px;
    border-left: 7px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 7px solid #ffffff;
    position: absolute;
    left: 7px;
    top: -4px;
}
.searchInput:focus + .tooltip-search {
  display: block; /* Show tooltip when input is focused */
}
.min-h-full{
  min-height: 60vh;
  align-items: center;
  justify-content: center;
}
.m-select{
  appearance: none!important;
  -webkit-appearance: none!important;
  -moz-appearance: none!important;
  background-color: rgb(255 255 255 / 59%) !important;
  border-color: #f0f0f0!important;
  border-radius: 100px!important;
  font-size: .9rem!important;
}
.add-img-placeholder{
  filter: grayscale(1);
}
.remove-img-btn{
  position: absolute!important;
    right: -11px!important;
    top: -6px!important;
    padding: 0px!important;
    background: #efc5b8!important;
    width: 20px!important;
    height: 20px!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
}
.c-row{
  display: grid!important;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}
/* .c-row > div.my-2:last-child, .c-row > div.my2:last-child {
  grid-column: span 3; 
} */
/* Case 1: If only 1 item in the last row, it spans all 3 columns */.c-row > div.my-2 {
  grid-column: span 1; /* Each item takes 1 column */
}

/* Case 2: If the number of items is 1 or 3n+1 (last item spans 3 columns) */
.c-row > div.my-2:nth-last-child(1):nth-child(3n+1) {
  grid-column: span 3; /* Last item takes up the full row */
}

/* Case 3: If the number of items is 2 or 3n+2 (last 2 items span 1.5 columns each) */
.c-row > div.my-2:nth-last-child(2):nth-child(3n+2) {
  grid-column: span 1; /* First of the last 2 items takes up 1.5 columns */
}

.c-row > div.my-2:nth-last-child(1):nth-child(3n+2) {
  grid-column: span 2; /* Second of the last 2 items takes up 1.5 columns */
}
.blurchart{
  min-height: 145px;
  filter: blur(3px) grayscale(1);
  pointer-events: none;
}
.c-table-2 td, .c--table-2 tr{
  border-color: #f0f0f0;
}
.c--table-2 tr{
  margin-bottom: 2px;
}
:root{
accent-color:#ff7200;
}
div#root ~ div > div[style*="position: fixed"][style*="z-index: 99999"][style*="background-color: rgba(0, 0, 0, 0.5)"], div#root ~ [style*="position: fixed"][style*="font-family: Helvetica Neue, Helvetica, Arial"] {
 
  display:none!important;
  }
.tags-container{
  display: inline-flex;
  gap:.5rem;
  flex-wrap:wrap;
}

span.tag-item{
    border: 1px solid #f0f0f0;
    border-radius: 6rem;
}
span.tag-item:hover{
  border-color: #ff7200;
}
.react-datepicker__day--selected {
  background-color: #ff7200 !important;
  color: white !important;
  border-radius: 50%;
}

.dob-datepicker-container .react-datepicker__input-container{
  border:none;
}
@media  screen and (max-width:767px) {
  nav.breadcrumb{
    overflow: hidden;
  }
  .qThumb{
    min-width: 100%;
    width: 100%;
  }
  /* css for careernews */

  .share-custom{
    top:15px;
  }
  /* .offer-btn, #dropdown-basic-button{
    padding:7px!important;
  } */
  .mw-420{
    min-width: 100%;
    max-width: 100%;
  }
  
  .max-50{
    max-width: 100%;
   }
  .img-res{width:40px!important;height:40px!important;}
  .title-review{
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  small, .small {
    font-size: 0.775em!important;
}
.why-rejected{
  position: absolute;
  bottom: 11%;
  left: 14px;
}
.s-rate{
  position: absolute;
    right: 5%;
    bottom: 39%;
}
  .prnext{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
  }
  .prnext span{
    width:100%;
  }
  .nxt-prev{
    position: static;
  }
  .heading-main h5, .heading-main h6{font-size: .9rem!important;}
  .border-sm-top{border-top:1px solid #f0f0f0;}
  .ml-s-70{
    margin-left:70px;
  }
  .pauseedit{
    width:50px;
  }
  .detailPage .thumbnail-container{
    width: 100%;
     height: 200px !important;
  }
  .detailPage .thumbnail-container img{
   width:100%!important;
   max-height: 300px!important;
   height:auto!important
  }
  .lafter{
    background-color: #f9fafa!important;
  }
  .afilter span{
    font-size: .8rem!important;
  }
}
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';