@media screen and (min-width:768px){
    .mobile-view{
        display: none;
    }
}
@media screen and (max-width:767px){
    .desktop-view{
        display: none;
    }
    h2{
        font-size: 30px!important;
    }
    h3{
        font-size: 20px!important;
    }
    .hero_section{
        padding: 2rem 0.5rem;
    }
    .hero_title_box .hero_title {
        font-size: 34px;
        line-height: 40px;
        letter-spacing:0;
        line-height: 42px;
    }
    .hero_title_box p {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0;
    }
    .explore_btn {
        font-size: 18px;
        letter-spacing: 0px;
        padding: 1rem 1rem;
    }
    .hero_img img{
        margin-top: 2rem;
    }
    .first_module {
    padding: 2rem 0.5rem;
    }
    .first_module_title, .first_module .career_content h3, .boost_content h3{
        font-size: 30px;
        font-weight: 900;
    }
    .sub_module1 .submodule1_content {
        padding: 1rem 0rem;
    }
    .sub_module1 .submodule1_content p, .feature1 .feature1_content p, .box1 .zig_zag_content p, .knowledge_box1 .knowledge_box_content p, .boxing1 .video_content p, .first_box .box_content p, .network_box .box_content p, .educational_box .education_content p, .network_box .box_content p, .community_box_content p, .collab_box .collab_content p {
        font-size: 14px;
        line-height: 21px;
    }
    .sub_module1 .submodule1_content h3 {
        font-size: 24px;
        font-weight: 800;
    }
    .feature1, .feature2, .feature3{
        height: auto;
    }
    .zig_zag_box .design_box4{
        margin-left: 0rem;
    }
    .boost {
        padding: 2rem 1rem;
    }
    .boxing1{
        height: auto;
        margin-bottom: 1rem;
    }
    .boost_content{
        padding: 2rem 0rem;
    }
    .community_box{
        padding: 1rem;
        display: block;
    }
    .community_box .community_box_content{
        margin-top: 2rem;
        padding-left: 0.5rem;
    }
    .collab_box{
        height: auto;
        margin: 1rem;
    }
    .subscribe_newsletter{
        width: 100%;
    }
    .subscribe_newsletter .subscribe_btn{
        width: 55%;
        font-size: 14px;
        font-weight: 600;
    }
    .subscribe_newsletter input{
        width: 60%;
    }
    .collabration_content{
        padding: 1rem;
    }
    .center_community_content p, .collabration_content p, .community_network_content p, .networking_building_content p, .educational_network_content p, .first_module_subtitle, .boost_content p, .knowledge_content_box p, .first_module .career_content p{
        font-size: 16px;
    line-height: 24px;
    }
    .network_box{
        margin-top: 2rem;
    }
    .first_box{
        height: auto;
    }
    .join_community_box {
        padding: 1rem;
    }
    .center_community_content{
        padding: 1rem 0rem;
    }
    .comunity_graphic{
        padding-top: 3rem;
    }

}