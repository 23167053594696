*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
::-webkit-file-upload-button { cursor:pointer; }
html{
	width: 100%;
	height: 100%;
}
li{
	/*list-style: none;*/
}
body{
	max-width: 100%;
	word-wrap:break-all;
	background: /*#F8F8F8*/#f9f9f9;
}
.card-body{
	background: /*#f7245b*/ #fff;
	border-radius: 0.8rem;
}
.bg-design{
	background: /*#f7245b*/ #fff;
}
/*.menu-box{
	height: 100vh;
}*/
.card-img{
	margin-right: 1rem;
}
.card-body-img{
	background: #fafafa;
	border-radius: 0.8rem;
}


.menu-box{
	width: 23%;
	transition: 700ms;
	overflow: hidden;
	height: 100vh;
}
.menu-box.hide{
	width: 6%;
	transition: 700ms;
}
.hide-logo{
	display: none!important;
}

.job-card div{
	padding-right: 4rem;
	color: #65708D;
}

.job-card-table{
	padding: 1rem;
}
/*.menu-box.hide .logos .small-icon-logo img{
	display: block;
	width: 201px;
}*/
.menu-box.hide  .item .item-list .item-link svg{
	width: 45px!important;
    padding: 0.6rem;
    transition: 700ms;
    margin-right: 35px;
    margin-left: 10px;
}
.menu-box.hide  .item .item-list{
	padding: 0.5rem 0.3rem 0.5rem 0rem;
    width: 201px;
}
/*.menu-box .logo{
	display: block;
}
.menu-box.hide .small-icon-logo{
	display: block;
}*/
.content-box{
	width: 94%;
	transition: 700ms;
}
.content-box.full{
	width: 94%;
	transition: 700ms;
}

.small-menu a img{
	display: block;
	margin: auto;
	align-items: center;
	/*padding: 1rem;*/
}
.small-menu li{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 3rem;
}
.small-menu li a img{
	width: 40px;
}
.small-icon-logo{
	width: 70px;
    display: block;
    /* justify-content: center; */
    margin: auto;
    padding: 0.8rem 1rem 0.5rem 0rem;
}
/*.menu-box, .content-box{
	width: 100%;
	height: 100%;
	
	word-wrap:break-all;
}*/
.logo img{
	padding: 1rem 2.4rem;
}
.menu-toggle i{
	font-size: 35px;
	color: #021442;
	margin-top: 10px;
    margin-left: -14px;
	/*width: 100px;*/
}
/*.job-menu li{
	color: #65708D;
	font-size: 20px;
	font-weight: 400;
	padding-right: 5rem;
}*/
.no-menu li{
	padding: 1rem 0rem 1rem 0.5rem;
    font-size: 20px;
    font-weight: 500;
    color: #021442;
}
.job-box{
	padding: 0.8rem;
    border-radius: 2rem;
    margin-left: 2rem;
}
.job-box div{
	padding: 0rem 0rem 0rem 0rem;
    font-size: 16px;
    font-weight: 500;
    color: #021442;
   
}
/*.item{
	padding: 0rem 1rem;
}*/
.item .item-list{
	padding: 1rem 0rem 1rem 2.2rem;
	list-style: none;
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.item .item-list .item-link{
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 400;
	color: #A5A5A5;
}
/*.item .item-list .item-link:hover, .item .item-list .item-link svg path:hover{
	color: #F08645;
	stroke: #F08645;
}*/
/* .list-menu::after{
	content: " ";
	display: inline-block;
	width: 4px;
	height: 26px;
	background: #F08645;
	border-radius: 10px;
} */
.item-list .item-link{
	position: relative;
	width: 100%;
}
.item-list .item-link.active::after{
	content: " ";
	display: inline-block;
	width: 4px;
	height: 26px;
	background: #F08645;
	border-radius: 10px;
	position: absolute;
    right: 0;
    top: 0;
}
.item .item-list .item-link img{
	padding-right:1.25rem;
	/* width: 50px; */
	object-fit: contain;
}
/*dropdown-menu*/
.dropdown-submenu ul{
	padding-left: 0.8rem;
}
.dropdown-submenu ul li a{
	font-size: 14px;
    font-weight: 400;
    color: #a5a5a5;
    display: flex;
    justify-content: start;
    align-items: center;
    width: max-content;
}
.dropdown-submenu ul li a svg{
	width: 18px;
	margin-right: 15px;
}
.active-dropdown{
	color: #f08645!important;
}
.dropdown-submenu ul .list-menu{
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.dropdown-submenu{
	/*height: 0px;*/
	/*transition:5s;*/
	overflow: hidden;
}
.dropdown-submenu.hide{
	height: 0px;
	transition:900ms;
	overflow: hidden;
	width: 100px;
}
.dropdown-submenu.show{
	height:auto;
	transition: 900ms;
	overflow: hidden;
	margin-left: 1.5rem;
}
/*.item .item-list .item-link:active{
	border-right: 3px solid #ff7200;
}*/
.item .item-list .active{
	/*border-right: 4px solid #F08645;*/
	color: #F08645;
	font-weight: 500;
	border-radius: 0.2rem;
}
.top-search-box{
	background: none;
    outline: none;
    border: none;
}



/*content section*/
.search-box{
	padding: 2rem 0rem;
	display: flex;
	align-items: center;
}
.search-box input{
	height: 35px;
	border: none;
	border-right: 1px solid #07102F;
	background: #FEFEFE;
	border-radius: 10px 0px 0px 10px;
}
.search-box .search-icon{
	/*position: absolute;*/
	padding: 1rem;
	display: flex;
	align-items: center;
	color: #0F3F62;
}
.search-box .search-icon i{
	font-size: 22px;
	color: #0A043C;
}
.profile-box .profile-header{
	display: flex;
	justify-content: end;
	/* justify-content: space-between; */
	align-items: center;
	padding: 1rem 1rem;
}
.question-card{
	padding: 1rem;
}
/*.profile-box .profile-header li{
	
	padding: 0rem 1.2rem;
}*/
.profile-header li{
	display: flex;
	align-items: center;
}
.profile-header li a i{
	font-size: 20px;
	color: #0A043C;
}
.profile-header li .profile-img{
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 50%;
	
}
.profile-header li a strong{
	padding: 0rem 1rem;
	font-size: 18px;
}
.profile-header li p{
	padding: 0rem 1rem;
}
.prifile-name{
	cursor: pointer;
}
.graph-container{
	padding: 1rem 1rem 1rem 2rem;
}

.tab-menu{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.tab-menu ul{
	display: flex;
	/*align-items: center;*/
	justify-content: space-between;
}
.tab-menu ul li, .graph{
	padding: 1rem;
}
.tab-menu ul li a{
	color: #65708D;
	font-weight: 600;
}
.tab-menu ul .active a{
	border-bottom: 3px solid #021442;
}
.tab-menu ul li a:active/*, .tab-menu ul li a:hover*/{
	color: #021442;
	/*text-decoration: underline;*/
	border-bottom: 3px solid #021442;
	transition: all 0.5s ease-in-out;
}
/*.tab-menu ul .active{
	border-bottom: 3px solid #021442;
}*/

/*.summary-container{
	padding-top: 16px;
}*/
.summary-main-box{
	/*border: 1px solid #a0a0a02e*//*rgba(10, 4, 60, 0.6)*/;
	padding:1rem;
    border-radius: 0.8rem;
}
.summary-main-heading{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.4rem 0.2rem;
}
.question-53{
	display: flex; 
	justify-content: space-between;
	align-items: flex-start;
	padding: 11px 2px;
}
.group-1, .news-child-box, .para{
	display: flex;
	align-items: center;
}
.group-1 h3{
	line-height: 20px;
}
.summary-main-heading h2{
	font-size: 20px;
    font-weight: 600;
    color: #0A2B43;
}
.question-53 .group-1 h3 span{
	font-size: 13px;
    color: #79869F;
    font-weight: 400;
}
.group-1 a{
	padding: 1px 4px 1px 0px;
}
.news-box,  .basic-plan-box{
	display: flex; 
	justify-content: space-between;
	align-items: center;
	/*padding-bottom: 1rem;*/
}
.news-subscribe{
	padding: 2.3rem 2rem 1rem;
}
.heading-star{
	font-size: 24px;
	font-weight: 700;
	color: #0A2B43;
}
.news-child-box, .new-img, .new-content{
	padding: 0.6rem 0.6rem 0rem 0rem;
}
.new-content .para .vl{
	width: 1.5px;
    height: 15px;
    background: #A0A0A0;
    margin: 4px 10px;
}
.new-content .para p{
	font-size: 13px;
	color: #a0a0a0;
}
.new-content p{
	font-size: 13px;
	color: #6AD175;
}
.basic-plan-box .change-btn, .start-btn{
	background: #ffbd9540;
    color: #F08645;
    padding: 5px 27px;
    border-radius: 1rem;
    font-weight: 500;
}
.price-amt{
	font-size: 18px;
	font-weight: 600;
	color: #0E1566;
	line-height: 36px;
}
.basic-img{
	padding: 0.8rem 0.8rem 0rem 0.8rem;
    border-radius: 1.6rem 1.6rem 0rem 0rem;
    height: 225px;
    object-fit: cover;
}

.all-jobs{
	text-align: center;
    display: block;
    color: #419CF0;
    font-weight: 500;
    font-size: 18px;
    padding: 1rem 0rem 0rem;
}
.view-all-btn{
	font-size: 13px;
	color: #79869F;
}

.img-icons{
	color: #F08645;
	/*background: #ff7200;*/
}
.item .item-list .item-link svg{
	padding-right: 0.5rem;
	width: 28px!important;
	height: auto!important;
}
.vertical-line::before {
    display: inline-block;
    padding:0rem 0.5rem;
    color: #a5a5a5;
    /*height: 50px;*/
    /* content: "|"; */
}


/* Css Design of Question Bank */
.input-container{
	height: 53px;
}
::placeholder{
	font-size: 12px;
	padding-bottom: 10px;	
}
.input-design-box{
	margin-top: -4px;
    float: left;
}
.filter-text{
	padding-top: 1px;
}
.breadcrumb{
	/*padding: 10px 15px!important;*/
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /*padding: 0.75rem 1rem;*/
    /*margin-bottom: 1rem;*/
    list-style: none;
    border-radius: 0.25rem;
}
.breadcrumb,.breadcrumb-item + .breadcrumb-item::before{
	color: #a5a5a5!important;
}
.bg-themecolor {
	background-color: #ff7200!important;
}
::marker{
	font-weight: bold;
	color:#ff7200
}
.mainQ-title{
	font-size: 22px!important;
    padding-left: 31px;
    position: relative;
}
.mainQ-title:before{
	content: 'Q.';
    font-size: 25px;
    margin-right: 10px;
    color: #bfd6df;
    position: absolute;
    left: -1px;
    top: -3px;
}
.card-body {
    background: /*#f7245b*/ #fff;
    border-radius: 0.4rem!important;
}
.arr-r{
	position:absolute;
	bottom:20px;
	right:20px;
	display: none;
}
.breadcrumb a{
	/* color: #a5a5a5; */
	color:#1f659b;
	font-size: 14px;

}
.breadcrumb li.rest-bread{
	min-width: fit-content;
}
.breadcrumb li.active a {
    color: #a5a5a5!important;
    /* color: #F08645!important; */
    font-weight: 500;
	max-width: 420px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}
.breadcrumb-item{
	list-style: none;
}
.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding:0rem 0.1rem;
    color: #6c757d;
    content: "/";
}
.breadcrumb-item2+.breadcrumb-item2::before {
    display: inline-block;
    padding:0rem 0.3rem;
    color: #6c757d;
    content: "|";
}
.category-text{
	color: #0A2B43;
}
.child-box nav{
	display: flex;
    justify-content: space-between;
    align-items: center;
}
.pagination{
	display: flex!important;
	justify-content: end!important;
}
.prev-arrow, .next-arrow, .pagging{
	border: 1px solid #C2C2C2;
	border-radius: 13px;
}
.pagging li button{
	color: #202020;
}
.pagging li .active{
	border-radius: 13px;
	color: #fff;
}
.filter-box form label{
	/*position: absolute;
    top: 15%;
    right: 15%;*/
    font-size: 16px;
    font-weight: 600;
    color: #202020;
    padding-right: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 25px;
    padding-top: 0.2rem;
}
 .filter-box form select:focus{
 	outline: none;
 	border: none;
}
.filter-box form select option{
	padding-left:1rem;
	font-size: 14px;
	color: #a5a5a5;
}
.filter-box form input:hover, .filter-box form input:focus{
	outline: none!important;
	border: none!important;
	box-shadow: none!important;
}
#disabled-input:hover, #disabled-input:focus{
	outline: none!important;
	border: none!important;
}
/*.filter-box form  input{
	padding: 0rem 0rem 0.3rem;
}
*/
/*category page*/
.category-main-box{
	display: flex;
	padding: 0.5rem 0rem;
}

.category-main-box img{
	min-width: 140px;
	max-width: 140px;
	height: 80px;
}
.category-main-box .category-name h3{
	color: #0A2B43;
	font-weight: 600;
	font-size: 18px;
}
.category-main-box .category-name p{
	color: #a0a0a0;
	font-weight: 500;
	font-size: 14px;
}
.category-main-box .category-name .coming-text{
	color: #6AD175;
}

.fav-btn{
	min-width: 225px;
}
/*listing design*/
.child-item{
	padding: 0rem 1rem;
}
.child-item .item-list{
	padding: 0.3rem 1rem;
}
.child-item .item-list .item-link{
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	color: #A5A5A5;
	padding: 0.5rem 1rem;

}

/*.item .item-list .item-link:active{
	border-right: 3px solid #ff7200;
}*/
.child-item .item-list .active, .child-item .item-list a:hover{
	background: rgba(255, 189, 149, 18%);
	color: #F08645;
	border-radius: 2rem;
}


 .child-content li{
 	padding: 0.5rem 1rem 0.5rem 1rem;
 	display: flex;
 	line-height: 35px;
 	align-items: start;
 	/*align-items: center;*/
 }
 .child-content li a{
 	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	color: #383A3E;
	/*line-height: 40px;*/
	/*text-decoration: underline;*/
 }
 .child-content li .active, .child-content .active, .child-content li:hover, .child-content li a:hover{
 	color: #F08645;
 }
 .child-content li  img{
 	padding: 0rem 0.8rem 0rem 0rem;
 }
 /* .child-content li .number-cy{
	background: #f3f3f3;
	color: #383a3e;
	width: 40px;
	height: 40px;
	text-align: center;
	border-radius: 50%;
	font-size: 20px;
	font-weight: 600;
	margin-right: 15px;
 } */

 .child-content li .number-cy {
	display: inline-block;
	border-radius: 50%;
	min-width: 40px;
	min-height: 40px;
	padding: 5px;
	background: #f3f3f3;
	color: #383a3e;
	text-align: center;
	line-height: 1;
	box-sizing: content-box;
	white-space: nowrap;
	font-size: 14px;
	font-weight: 600;
	margin-right: 15px;
  }
  .child-content li .number-cy:before {
	content: "";
	display: inline-block;
	vertical-align: middle;
	padding-top: 100%;
	height: 0;
  }
  .circle span {
	display: inline-block;
	vertical-align: middle;
  }
.subscribe_btn{
	background: #F08645;
	color: #fff;
	font-weight: 600;
	padding: 1rem 3.5rem;
    border-radius: 0.8rem;
}

 /*feeds design*/
 .feedback-item{
 	padding: 5px 0px 10px;
 }
 .feedback-item li{
 	padding:1px;
 	color: #a5a5a5;
 	font-size: 14px;
 }
 .feed-profile-box {
 	display: flex;
 	justify-content: space-between;
 	align-items: center;
 }
 .small-logo-box img{
 	width: 75px;
 }
 .small-logo-text h3{
 	font-size: 18px;
    font-weight: 700;
    color: #202020;
 }
.small-logo-text p{
    color: #a5a5a5;
 }
.btn-box{
	background: #F08645;
	padding: 0.6rem 2.2rem;
	border-radius: 1rem;
	color: #fff;
	font-weight: 600;
	font-size: 22px;
}
.divider{
	margin: 1rem 0rem;
	height: 0.1px;
	color: #a0a0a0;
}
.text-icon-box{
	display: contents;
}
.text-icon-box .text-group h3{
	font-size: 18px;
    font-weight: 600;
    color: #545454;
}
.text-icon-box .text-group p{
	font-size: 14px;
    color: #545454;
    font-weight: 500;
}
.small-logo-text a{
	padding: 0rem 0.6rem;
	font-size: 28px;
}
.small-logo-text a .fa-telegram{
	color: #0088cc;
}
.small-logo-text a .fa-whatsapp{
	color: #25D366;
}
.small-logo-text a .fa-facebook-f{
	color: #4267B2;
    /*padding: 0.2rem 0.5rem;
    border-radius: 10px;
    font-size: 23px;
    background: #4267B2;*/
}
.small-logo-text a .fa-youtube{
	color: #FF0000;
}
.question-box p{
	color: #F08645;
}
.question-box h1{
	color: #202020;
    font-size: 18px;
    font-weight: 600;
}
.question-box ol li{
	padding: 0.5rem 0rem;
	font-size: 14px;
	font-weight: 500;
	color: #202020;
}
.question-box .btn-soft{
	background: #f2f2f2;
    color: #a0a0a0;
    padding: 0.5rem 0.5rem!important;
    border-radius: 0.4rem;
}

.question-box .btn-soft.comment-btn{
	background: #f0f0f0;
	color: #ff7200;
    padding: 0.7rem 1rem;
    border-radius: 0.8rem;
}
.question-box .btn-soft.re-comment-btn{
	background: #f0f0f0!important;
	color: #ff7200;
    padding: 0.7rem 1rem;
    border-radius: 0.8rem;
	font-size: 16px!important;
}
.blue-link{
	color:#0056b3;
}
.blue-link:hover{
	color:#ff7200;
}
.question-box .btn-soft.comment-btn:disabled{
	background: #f2f2f2;
    color: #a0a0a0;
}
.header1 {
    background: #fafbfc;
    border-bottom: 4px solid #2879F5;
}
.header1 h5{
	font-size: 18px;
    font-weight: 600;
    color: #545454;
}
.trending-vdo .team-member {
    border-radius: 10px;
    box-shadow: none;
  }

  .trending-vdo .team-img-meta {
    position: absolute;
    bottom: 0px;
    border-radius: 7px;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgb(0 0 0 / 82%), transparent);

    
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 10px;
  }
.trending-vdo .team-member {
      background: transparent !important;
    }

  .trending-vdo .nav-pills .nav-link {
    padding: 0px 20px !important;
  }

  .trending-vdo .vid-container-new {
    height: 200px;
    overflow: hidden;
    width: 100%;
    position: relative;
    display: block;
    border-radius: 10px;
    max-height: 200px;
  }

  .trending-vdo .team-member-photo,
  .trending-vdo .team-member {
    /* min-width: 113px; */
    /* max-width: 113px; */
	background: #f0f0f0;
    border-radius: 11px;
  }
  .slick-arrow.slick-prev {
    position: absolute;
    left: 0px;
    top: calc(50% - 5px);
    z-index: 1;
    width: 40px;
    height: 40px;
    padding: 13px;
    border-radius: 50%;
}
.slick-arrow.slick-next {
    position: absolute;
    right: 20px;
    top: calc(50% - 5px);
    z-index: 1;
    width: 40px;
    height: 40px;
    padding: 13px;
    border-radius: 50%;
}
.slick-prev:before {
    content: '←'!important;
    color: white!important;
    width: 50px!important;
    display: block!important;
    font-size: 36px!important;
}
.slick-next:before {
    content: '→'!important;
    color: white!important;
    width: 50px!important;
    display: block!important;
    font-size: 36px!important;
}
  label.text-white{
	font-size: 13px;
	word-break: break-word;
  }

  .trending-vdo .vid-container-new img {
    display: block;
    width: 100%;
    position: absolute;
    border-radius: 8px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  

  .trending-vdo .videos-main-container {
    overflow-y: hidden;
    flex-wrap: nowrap;
    max-height: 236px;
    overflow: hidden;
    position: relative;
  }

  .trending-vdo .videos-main-container>div {
    flex-wrap: nowrap;
    margin-bottom: 10px;
    overflow: auto;
  }

  .trending-vdo .slide-prev-video,
  .trending-vdo .slide-next-video {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    border-radius: 50%;
    z-index: 3;
    border: none;
    position: absolute;
    top: calc(50% - 14px);
    background-color: #fff;
    cursor: pointer;
  }

  .trending-vdo .slide-next-video {
    right: -18px;
    bottom: 0;
  }

  .trending-vdo .slide-next-video:focus,
  .trending-vdo .slide-prev-video:focus {
    outline: none;
    box-shadow: none;
  }

  .slide-next-video:hover,
  .trending-vdo .slide-prev-video:hover {
    color: #333;
  }

  .trending-vdo .slide-prev-video {
    left: -18px;
    bottom: 0;
  }

  .trending-vdo .videos-main-container>div {
    display: -webkit-box;
    display: -moz-box;
    padding-bottom: 20px;
  }

  .trending-vdo .video-container {
    width: 160px;
    height: 230px;
    overflow: hidden;
    position: relative;
  }

  .trending-vdo .video-container .user {
    position: absolute;
    bottom: 10px;
    left: 15px;
    color: white;
    z-index: 3;
  }

  .trending-vdo .profile-img {
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    z-index: 2;
    border: 2px solid #fcb917;
    margin-bottom: 10px;
	position: fixed;
    top: 15px;
    margin-left: -50px;
  }

  .trending-vdo .video-container>img.poster {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 190px;
    z-index: 1;
  }

  .trending-vdo h2.h2-xs {
    font-size: 2.15rem;
  }

  .trending-vdo .profile-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 2px;
    transform: translate(-50%, -50%);
    border-radius: 50% !important;
	height:27px;
	min-width: 100%;
  }

  .trending-vdo .features-slider {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 0px !important;
  }

  .trending-vdo .slick-slide {
    /* width: 113px !important; */
    border-left:2px solid #fff;
    border-right:2px solid #fff;
  }
.comment-btn{
	position: absolute;
    padding: 0!important;
    right: 26px!important;
    background: none!important;
    font-size: 31px!important;
}
.comment-profile img{
	width: 40px !important;
    border-radius: 50% !important;
    height: 40px !important;
    object-fit: cover  !important;
}
.comment-box{
	width: 87%;
	margin: 0rem 1rem;

}
.Toastify{
	position: relative;
	z-index: 999999;
}
.question-box form select{
	background: transparent;
    padding: 0.4rem 1.3rem;
    border-radius: 2rem;
    color: #F08645;
    font-weight: 500;
}
.new-com-select{
	background: rgba(255, 189, 149, 18%);
	border-radius: 2rem;
	padding: 0.4rem 1.3rem;
    border-radius: 2rem;
    color: #F08645;
    font-weight: 500;
}
.question-box form select option {
	height:100px;
  margin:2rem;
  line-height:30px;
  padding: 1rem;
}
.otherPlatformLink .active, .otherPlatformLink:hover > div > div > div > span, .otherPlatformLink:hover > a > span{
	color:#ff7200!important;
}
input[type="text"]:focus-visible,input[type="email"]:focus-visible, select:focus-visible{
	outline-color: rgba(247, 164, 10, .4);
}
.text-blue{
	color:#0056b3;
}
.reply-box h2 span{
	float: right;
	display: flex;
	align-items: center;
	padding: 0rem 5rem;

}
.reply-box h2{
	font-size: 18px;
	color: #202020;
	font-weight: 500;
}
.reply-box p{
	/* font-size: 16px; */
	color: #686767;
	/* font-weight: 400; */
}

/*payment section*/
.cart-detail-box{
	/*margin: 2rem 1rem 0rem 0rem;*/
    padding: 1rem 3rem 1rem 3rem;
}
.profile-header li a .fa-cart-shopping{
	/*background: #F08645;
    color: #fff;*/
    padding: 0.8rem;
    border-radius: 50%;
    font-size: 100%;
}
.box-1{
	border: 2px solid #F08645;
    padding: 1rem 1rem;
    border-radius: 0.8rem;
}
.content h2{
	font-size: 22px;
	font-weight: 600;
	color: #021442;
}
.box-2{
	border: 2px solid #CCCCCC;
    padding: 1rem 1rem;
    border-radius: 0.8rem;
}
.box-2 a .fa-check-square{
	font-size: 30px;
    display: flex;
    margin-top: -15px;
    color: #194FA0;
} 
.content p {
	color: #3E3E3E;
    font-size: 17px;
    font-weight: 500;
}
.checkout-btn, .add-card-btn{
	display: block;
	background: #F08645;
	color: #fff;
    text-align: center;
    padding: 1rem;
    border-radius: 0.6rem;
    margin: 3rem 0rem;
    font-size: 22px;
    font-weight: 500;
}
.Add-card-btn{
	display: block;
	background: #979797;
	color: #fff;
    text-align: center;
    padding: 1rem;
    border-radius: 0.6rem;
    font-size: 22px;
    margin-top: 1rem;
    font-weight: 500;
}
.card-heading{
	font-size: 24px;
    font-weight: 600;
    padding: 0rem 0rem 0.8rem;
    color: #424347;
}
.modal-header h2{
	font-size: 30px;
    font-weight: 600;
    color: #424347;
}
.card-detail p{
	font-size: 16px;
    color: #999;
    font-weight: 400;
}

.card-detail h3{
	font-size: 24px;
    font-weight: 500;
    color: #3E3E3E;
}
.order-box img{
	width: 225px;
}
.order-text{
	text-align: center;
    font-size: 26px;
    font-weight: 700;
    padding-top: 1rem;
    color: #F08645;
}
/*explore*/
.basic-btn{
	text-align: center;
    color: #CA514C;
    border-radius: 0.8rem;
    padding: 0.8rem 1.5rem;
    font-weight: 500;
    background: rgba(255, 232, 232, 0.5);
}
.accordion-box{
	border-radius: 0.6rem;
}
/*.accordion-box .collapsed{
	background: #F08645;
	border-radius: 0.6rem 0.6rem 0rem 0rem;
}*/

.accordion-box .collapsed a{
	color: #fff;
}
.active-accordion, .active-focus {
  background-color: #F08645!important;
  border-radius: 0.6rem 0.6rem 0rem 0rem!important;
}
.active-accordion a, .active-accordion a svg path{
  fill: #fff!important;
  color: #fff!important;
}
.active-accordion .accordion-symbol .hide{
	display: none;
}
.active-accordion .accordion-symbol .show{
	display: block;
}

.accordion-heading{
	background: #fff;
	border-radius: 1rem;
}
.accordion-heading div{
	font-size: 20px;
	color: #202020;
	font-weight: 500;
}
.accordion-symbol{
	font-size: 30px;
    font-weight: 700;
}
.accordion-content-box{
	border: 1px solid #F08645;
	border-radius: 0rem 0rem 0.6rem 0.6rem;
}
.content-collapse{
	border: 1px solid #CCCCCC;
	border-radius: 0.6rem;

}
.accordion-content-box .active{
	border: 2px solid #6AD175;
}
/*ol li {
  counter-increment: muffins
}
ol li:before {
  content: counter(muffins) ". ";
}
ol {
  list-style: none;
  counter-reset: muffins;
}*/
 ::-webkit-scrollbar {
  width: 4px;
}

 
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 3px grey;  */
  border-radius: 10px;
  /* background:transparent!important; */
}
 
 
::-webkit-scrollbar-thumb {
  /* background: #818181;  */
  background:rgb(0 0 0 / 17%);
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb:hover {
  background: #818181; 
}  

.scroll-container-custom::-webkit-scrollbar-thumb{
	background:transparent;
}
.scroll-container-custom:hover::-webkit-scrollbar-thumb{
	background:rgb(0 0 0 / 17%);
}

.ellipsis-text {
	max-width: 160px;
	white-space: nowrap;            /* Prevents text from wrapping */
	overflow: hidden;               /* Hides text that overflows the container */
	text-overflow: ellipsis;        /* Adds ellipsis (...) to the end of the overflowed text */
}

#qlist{
	max-height: calc(100vh - 300px);
}
#qBank{
	max-height: calc(100vh - 180px);
}
#qCat{
	max-height: calc(100vh - 205px);
}
#mViewQ{
	max-height: 500px;
}
/*skip dashboard*/
.skip-text{
	font-size: 18px;
    font-weight: 500;
    padding: 0rem 3rem;
    color: #F08645;
    text-decoration: underline;
}
.video-play{
	text-decoration: underline;
	color: #F08645;
	font-size: 16px;
	font-weight: 500;
}
.play-video-heading{
	color: #202020;
	font-size: 20px;
	font-weight: 500;

}

/*wallets*/
.underline-bottom::after{
	content: " ";
    display: block;
    width: auto;
    margin-top: 0.2rem;
    height: 4px;
    background: #F08645;
    border-radius: 10px;
}


/*table thead tr th{
	padding: 1rem 1rem 0.5rem 0rem;
	color: #65708D;
	font-size: 20px;
	font-weight: 400;
}
table tbody tr td{
	padding: 0.5rem 0.5rem 1rem;
    
    color: #021442;
    font-size: 18px;
    font-weight: 500;
}*/
thead .table-heading th{
	padding: 1rem ;
} 
tbody .table-label td{
	/*padding: 0.6rem 0rem;*/
	font-size: 16px;
    font-weight: 400;
    margin: 1rem 0rem;
}

/*withdrawal-payment*/
.search-text{
	font-size: 18px;
    font-weight: 600;
    color: #c7c7c7;
    padding: 0.3rem 0rem 0.8rem 0rem;
}

tbody .table-design td{
	padding: 1.6rem 0rem;
	font-size: 16px;
    font-weight: 400;
    margin: 1rem 0rem;
}
/*bank & tax information*/
.bank-detail{
	font-size: 20px;
    font-weight: 500;
    color: #202020;
    padding: 0.5rem 0.3rem;
}
.receive-text{
	color: #979797;
    font-size: 16px;
    font-weight: 400;
    padding: 0.2rem 0.3rem;
}
.info{
	color: #f08645;
	font-size: 16px;
}
/*help & support*/
.fixed-box{
	width: 275px;
	height: 275px;
}
.play-btn{
	/*font-size: 61px;
    color: #f08645;*/
    padding: 1rem 1rem 0.5rem;
    display: flex;
    justify-content: center;
}
.play-btn img{
	width: 65px;
}
.video-head{
	font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    padding: 1.5rem 1rem 0.8rem;
    display: block;
}
.video-discription{
	font-size: 16px;
    color: gray;
    line-height: 24px;
    display: block;
}

/*profile*/
.Edit-btn{
	background: #F08645;
	color: #fff;
	padding: 0.8rem 2.5rem;
    border-radius: 1rem;
}
.profile-img-profile{
	display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin: auto auto;
    border-radius: 1rem;
    padding: 0.3rem;
}
.profile-detail{
	padding: 0.3rem;
    line-height: 24px;
}
.profile-detail h3{
	font-size: 20px;
    font-weight: 500;
    color: #2e2e2e;
}
.profile-detail p{
	font-size: 14px;
    color: rgba(46, 46, 46, 0.7);
    font-weight: 400;
}
.profile-detail a i{
	color: #FFC107;
    padding: 5px;
    font-size: 12px;
}
.detail-box h3{
	font-size: 18px;
    font-weight: 500;
    color: #c7c7c7;
    margin-top: 1rem;
}
.detail-box h4{
	font-size: 16px;
    color: #202020;
    font-weight: 500;
}
.personal-information-heading{
	color: #F08645;
    font-size: 20px;
    font-weight: 600;
}
.about-box h3{
	font-size: 16px;
    font-weight: 500;
    color: #2e2e2e;
}
.about-box p{
	font-size: 18px;
	color: rgba(46, 46, 46, 0.7);
    font-weight: 400;
}
.container-box{
	/*border-top: 1px dashed #c2c2c2;*/
	border-bottom: 1px dashed #c2c2c2;
	padding-bottom: 2rem;

}
.content-item li{
	list-style: square;
	padding: 1rem 0rem;
	font-size: 18px;
	color: rgba(46, 46, 46, 0.7);
    font-weight: 400;
}
.profile-table th{
	text-align: left;
	padding: 1rem 0rem!important;
}
.profile-table td{
	text-align: left;
	padding: 1rem 0.5rem!important;
	line-height: 34px;
	font-size: 16px;
}
.information{
	line-height: 30px;
}
.information a{
	color: #f08645;
}
.information h3{
	font-size: 18px;
	font-weight: 500;
}
.information h4{
	font-size: 20px;
	font-weight: 400;
}
.information-forum h3{
	font-size: 18px;
	font-weight: 600;
	line-height: 30px;
	color: #3475A4;
}
.information-forum a{
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
	color: #202020;
}
.information-forum h2{
	color: #3475A4;
	font-size: 22px;
	font-weight: 600;
	padding-bottom: 1rem;
}
.service-box .price{
	color: #6418C3;
	font-size: 16px;
	font-weight: 500;
}
.service-box .service-content h3{
	font-size: 18px;
	font-weight: 600;
	color: #202020;
}
.service-box .service-content h5{
	font-size: 16px;
	font-weight: 600;
	color: #979797;
}
.service-box .service-content .star{
	font-size: 12px;
	color: #e5e5e5;
	
}
.service-box .service-content .star i{
	padding-right: 15px;
}
.service-box .service-content p{
	color: #979797;
	font-size: 16px;
}
.service-box .button-group .edit_button, .service-box .button-group .sales_button{
	color: #fff;
	padding: 0.6rem;
	display: block;
	text-align: center;
	border-radius: 0.8rem;
	font-weight: 400;
}
/*forum*/
.add-discussion-btn{
	background: #f08645;
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 1rem;
    /*margin-right: 2rem;*/
    font-weight: 500;
}

/*Membership Details*/
.plan-icon img{
	width: 65px;
}
.plan-heading h3{
	font-size: 22px;
    font-weight: 500;
    color: #202020;
    padding: 0rem 0rem 0.8rem 0rem;
}
.plan-heading h4{
	font-size: 16px;
    color: #979797;
    font-weight: 400;
}
.plan-details{
	font-size: 16px;
	color: #202020;
	font-weight: 400;
	line-height: 32px;
}
.active_btn{
	display: block;
    font-size: 18px;
    color: #fff;
    background: #a5a5a5;
    text-align: center;
    padding: 0.8rem;
    border-radius: 0.8rem;
    margin: 2rem 0rem 1rem;
}
.active_plan_btn{
		display: block;
    font-size: 18px;
    color: #fff;
    background: #f08645;
    text-align: center;
    padding: 0.8rem;
    border-radius: 0.8rem;
    margin: 2rem 0rem 1rem;
}
.active-plan{
	border: 2px solid #f08645;
}
.basic-plan{
	border: 2px solid #ccc;
}
.active-box{
	border-left: 10px solid #f08645;
	border-radius: 6px 0.8rem 0.8rem 6px;
}
/*.basic-rate{
	color: #202020;
}
*/
/*services*/
.information a img{
	width: 65px;
	margin: auto;
}
.information .element-details span i{ 
		width: 4px;
    height: 4px;
  }
  .information .active{
	color: #f08645!important;
  }

.information .element-heading{
	text-align: center;
    color: #202020;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding: 0.5rem 0rem;
}
.element-details{
	font-size: 12px;
    line-height: 22px;
    color: #979797;
    font-weight: 500;
    text-align: center;
}

/*for disabled link*/
.isDisabled{
	cursor: not-allowed;
	opacity: 0.5;
}
.starter_name{
	font-size: 18px;
	font-weight: 500;
	color: #202020;
}
.starter_name .plan-start{
	font-size: 12px;
	font-weight: 500;
	color: #f08645;
}
.expiry_date{
	font-size: 18px;
	font-weight: 500;
	color: #202020;
}
.expiry_date .date{
	font-size: 18px;
	font-weight: 700;
	color: #202020;
}

#pop_ads{
			display: none;
		}
	/*.feeds-card{
		overflow-y: auto;
    height: 320px;
	}*/
.upload{
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 2rem 0.5rem 0rem;
    border-top: 1px dashed #dedede;
    padding: 1rem 0rem 0.5rem;
}
.feeds .upload a{
	color: #646464;
	font-weight: 500;
}
.feeds-uploads .name-feed-card .name-card h3{
	font-size: 18px;
	font-weight: 500;
	color: #202020;
	line-height: 18px;
}
.feeds-uploads .name-feed-card .name-card h5{
	font-size: 14px;
	font-weight: 400;
	color: #a5a5a5;
}
.details_feed_card{
	font-size: 16px;
	font-weight: 400;
	padding: 0.5rem 0.5rem;

}

.like_comment_section{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0.2rem;
}
.like_comment_section p{
	color: rgba(0, 0, 0, 0.5);
	font-size: 12px;
	font-weight: 500;
}
.like-box{
	display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.8rem 0rem 0.2rem;
}
.like-box a{
	color: rgba(0, 0, 0, 0.5);
}
.edit_card{
	float: right;
    color: #a5a5a5;
    padding: 0.5rem;
}
.edit_card_1{
		float: right;
    color: #a5a5a5;
}
#gender{
	appearance: none!important;
	color: gray;
}
.edit_card_1 .edit_card i{
	float: right;
    color: #a5a5a5;
     padding: 0rem;
     font-size: 16px;
}
.profile-dropdown{
	position: absolute;
    background: #fff;
    padding: 1rem 4rem 0.5rem 1rem;
    right: 2%;
    border-radius: 0.4rem;
}
.profile-dropdown li a{
	display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #A5A5A5;
    padding: 0rem 0rem 0.5rem;
}
.profile-dropdown li a span i{
	color: #A5A5A5;
	font-size: 16px;
	padding-right: 0.8rem;

}
.profile-dropdown{
	opacity: 0;
	transition: 1500ms;
}
.profile-card-box:hover + .profile-dropdown{
	opacity: 1;
	transition: 1500ms;
}
.profile-dropdown:hover{
	opacity: 1;
	transition: 1500ms;
}
.profile-dropdown li.active a, .profile-dropdown li.active a span i {
	color: #F08645;
}
.video_close_btn{
	color: #fff;
    position: absolute;
    z-index: 999;
    right: 0;
    font-size: 26px;
}
.video_container{
	max-width: 50rem!important;
}
input.searchInput::placeholder, input.commentInput::placeholder{
	font-size: 14px!important;
	color:#ccc;
  }
 .profile-dropdowns{
	position: absolute;
    margin-top: 60px;
    background: white;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 23px rgba(0,0,0,0.2);
    padding: 16px;
    right: 10px;
	min-width: 220px;
 }
 .db-RegisterAndLoginBackground-firstLeftStripe {
    height: 40px;
    background-color: rgba(247, 164, 10, .4);
    top: 668px;
    left: calc(50% - 700px);
    right: calc(50% + 540px);
}
.shadow-custom{
	box-shadow:0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,0.12);
	border-radius:4px;
	max-width: 34rem!important;
    padding: 0rem 2rem!important;	
}
.fsize-8{
	font-size: .75rem;
}
.mec-3 {
    margin-right: 0.6rem !important;
}
.logo-container img{
	/* max-width: 70px;
    height: auto;
    border-radius: 21px; */
	max-width: 52px;
    height: auto;
    border-radius: 0px;
    margin-top: 0.1rem;
}
.logo-container a {
	text-align: left;
	text-decoration: none!important;
}
.db-RegisterAndLoginBackground-grayBackground {
    transform-origin: 0%;
    height: 1698px;
    background-color: rgba(247,250,252,.5);
    position: absolute;
    top: -1000px;
    left: 0;
    right: 0;
}
.main-container-login{
	z-index: 2;
	position: relative;
}
.bg-themecolor{
	background-color: #ff7200;
}
.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend, .db-RegisterAndLoginBackground-firstLeftStripe, .db-RegisterAndLoginBackground-firstRightStripe, .db-RegisterAndLoginBackground-secondLeftStripe {
    position: absolute;
}
.skewed-background{
	position: fixed;
    top: -250px;
    bottom: 0;
    left: 0;
    right: 0;
    transform: skewY(-12deg);
}
.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend {
    height: 10px;
    background-color:#ff730079;
    top: 698px;
    left: calc(50% - 700px);
    right: calc(50% + 540px);
}
.db-RegisterAndLoginBackground-secondLeftStripe {
    height: 40px;
	background-color: rgb(255 106 0 / 52%);
    top: 698px;
    left: -10px;
    right: calc(50% + 405px);
}
.db-RegisterAndLoginBackground-firstRightStripe {
    height: 40px;
	background-color: rgb(255 106 0 / 52%);
    top: 658px;
    left: calc(50% + 430px);
    right: -10px;
}

.library-item-container{
	overflow: hidden;
    display: block;
    margin: 0;
    height: 100%;
	color:white!important;
	text-decoration: none;
	padding:10px;
	font-size: 13px;
}
.suggested-slider .slick-slide{
	max-width: 146px;
	min-width: 136px;
}
.suggested-slider .slick-slide > div{
	gap: 10px;
    display: flex;
    height: 215px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 15px;
	text-transform: uppercase;

}
.library-item-container img{
	filter: brightness(0) invert(.9);
    rotate: 30deg;
    margin: 40px auto;
	max-width: 80%;
}
  @media screen and (min-width:768px){
	
	.scrolled-user{
		padding-bottom: 20px;
		/* position:relative; */
		padding-top: 10px;
	  }
	  .scrolled-user:after {
		width: 100%;
		height: 50px;
		position: fixed;
		bottom: 0;
		content: '';
		left: 0;
		background: linear-gradient(360deg, #f0f2f5, transparent);
		max-width: 280px;
		right: 20px;
		left: auto;
		margin-top: -10px;
		bottom: 0px;
	  }
	  .scrolled-user:before {
		width: 100%;
		height: 30px;
		position: fixed;
		top: 38px;
		content: '';
		left: 0;
		background: linear-gradient(180deg, #f0f2f5, transparent);
		max-width: 280px;
    right: 20px;
    left: auto;
    margin-top: -10px;
	  }
  }
  .comments-all{clear: both;}
  .clamping{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.feed-detail-breadcrumb li:nth-of-type(2),.feed-detail-breadcrumb li:nth-of-type(3){
	display:none;
}
.otherPlatformLink:hover{
	background: rgb(255 114 0 / 12%);
}
.scrolled-user .otherPlatformLink:hover{
	background: none!important;
}
.otherPlatformLink .active path{		
	stroke:#ff7200!important;
}
.otherPlatformLink .active img, .otherPlatformLink:hover img{
	/* filter: hue-rotate(110deg); */
	filter: brightness(0) saturate(100%) invert(33%) sepia(100%) saturate(2200%) hue-rotate(2deg) brightness(104%) contrast(101%);
}

.avatarIco{
	border-radius: 100px;
    filter: none;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 45px;
    left: 15px;
    border: 2px solid #fff;
    z-index: 1000!important;
}

.history-item p{
	
	overflow: hidden;
	white-space: nowrap;
width: 100%;
margin: 0;
padding:8px 6px;
position: relative;
}
.history-item p:hover:after, .history-item.active p:after{
	content:none;

}
.mh-74{
	min-height: 78vh;
}
.right-p{
	position: sticky;
    top: 80px;
}
.chatbox{
	position: sticky;
    bottom: 0px;
    left: 10px;
    right: 10px;
    padding-top: 8px;
    background: #fff;
    padding-bottom: 10px;
    box-shadow: 0px -3px 10px rgba(0,0,0,0.1);
	z-index: 100;
}
.chatbox-error{
	position: absolute;
    margin-top: -32px!important;
    padding: 0px 10px;
    font-size: 10.4px;
    right: 60px;
}
.chatbox input[type=text]{
	border-radius: 25px;
    height: 50px;
    background: #f0f0f0;
}
.chatbtn-container{
	width: 46px!important;
    height: 22px;
    position: absolute;
    right: 18px;
    top: 21px;
    cursor: pointer;
}
.copy-button{
	background: none;
  border: none;
  color: wheat;
  }
span.languages-type{
	display:flex;
	justify-content:space-between;
	align-items:center;
	background: #202123;
  width: 100%;
  padding: 2px 10px;
  font-size: 0.7rem;
  color: aquamarine;
  }
  #message{
	min-height: 64vh;
  }
#message ol,#message ul, #message menu {
    list-style: auto!important;
	padding-left: 1rem!important;
}
div.ques{
	/* background: #f8f8f8; */
    
    margin: 7px 5px;
    border-radius: 10px;
    /* border-top-left-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 5px 30px 0px, rgba(0, 0, 0, 0.05) 0px 3px 3px 0px; */
}
div.ques strong{
	padding: 10px;
    flex: 1 1;
    border-radius: 10px;
    background: #f8f8f8;
    border-top-left-radius: 0px;
}
div.ans{
	padding-left: 45px!important;
	margin-top: 15px;
	margin-bottom: 15px;
}
div.ques img{
	border-radius: 110px;
    border: 1px solid #f0f0f0;
    padding: 2px;
	width:30px;
	height:30px;
	margin-right: 10px;
}
.history-item p:hover{
	cursor: pointer;
	background:#f0f0f0;
	border-radius: 7px;
	text-overflow: ellipsis;
}
.history-item.active p:hover{
	cursor: pointer;
	background:#f0f0f0;
	border-radius: 7px;
	text-overflow: ellipsis;
}
.history-item.active p{
	cursor: pointer;
	background:#f0f0f0;
	border-radius: 7px;
	text-overflow: ellipsis;
}

.history-item p:after{
	content: ''; 
    width: 30px;
    height: 20px;
    background: linear-gradient(90deg, rgb(255 255 255 / 69%),#fff);
    position: absolute;
    right: 0;
}
.scroll-down-btn{
	position: fixed;
    bottom: 10rem;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background: rgba(0,0,0,0.3);
    margin: 0 auto;
    z-index: 10000;
    border-radius: 40px;
    font-size: 1.6rem;
    color: white;


}
.chat-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 100000;
    background: #ff7200;
    padding: 9px 11px;
    border-radius: 40px;
    color: white;
    box-shadow: 0px 0px 11px rgb(0 0 0 / 10%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 145px;
}
.chat-icon img {
    width: 35px;
    height: 35px;
    animation: scaleWithBounce 2s infinite;
    margin-right: 0px;
}
/* Scaling with bounce animation */
@keyframes scaleWithBounce {
	0%, 20%, 50%, 80%, 100% {
		transform: scale(1);
	}

	40% {
		transform: scale(1.3);
		/* Scale up factor, adjust as needed */
	}

	60% {
		transform: scale(0.8);
		/* Scale down factor, adjust as needed */
	}
}
  @media screen and (max-width:767px) {
	.react-datepicker__input-container  input,.react-datepicker__input-container  input:focus{
		height:44px;
		border-radius: .5rem!important;
	}
	.breadcrumb{
		flex-wrap: wrap!important;
	}
	/* .breadcrumb li:last-of-type{
		display: none;
	} */
	.chat-icon{
		width: 55px;
		overflow: hidden;
		bottom:90px;
		min-width: 55px;
	}
	.chat-icon span:last-of-type{
		display:none;
	}
	.suggested-slider .slick-slide{
		max-width: 146px;
		min-width: 126px;
		width:126px!important;
	}
	.breadcrumb-item {
		line-height: 20px;
	}
	.mainQ-title{
		font-size: 19px!important;
	}
	.category-main-box .category-name h3, .mb-2.category-text,.header1 h5 {
		font-size: 15px!important;
	}
	.fsize-11 {
		font-size: 13px;
		line-height: 20px;
	}
	#question-box{padding:0px!important;}
	#question-box .header1{padding-left:15px!important;}
	
	.feed-profile-box{
		flex-direction: column;
    text-align: left;
    align-items: flex-start!important;
	}
	.feed-profile-box .small-logo-text{
		margin-top: 20px;
		padding-left: 0!important;
	}
	.text-icon-box .text-group h3{
		font-weight: 700!important;
	}
	.qblistmost{
			padding-left: 0.7rem!important;
			padding-right: 0.2rem!important;
		
	}
	.question-box .btn-soft.comment-btn{
		padding:5px 0px !important;
		margin:0!important;
	}
	#nextprevbtn{
		max-width: 45%;
		min-width: 45%;
    padding: 9px!important;
	}
	ol.answer-card{
		padding-left: .04rem!important;
	}
	.suggested-slider .slick-slide > div{
		
		height: 200px;
	
	}
	.slick-track{
		display:flex!important;
	}
	.commentInput + img{
		margin-left: -30px;
	}
  }

/* .shadow{
	
    top: 40px;

} */
#uncontrolled-tab-example-tab-profile,#uncontrolled-tab-example-tab-contact,#uncontrolled-tab-example-tab-interview{
	padding:8px !important;
	background:#f1ffe2 !important;
	border-radius:10px !important;
	color:#000 !important;
}
#uncontrolled-tab-example-tab-profile:hover,#uncontrolled-tab-example-tab-contact:hover,#uncontrolled-tab-example-tab-interview:hover{
	color:#fff !important;
	background-color:#ff7200 !important;
}
#uncontrolled-tab-example-tab-profile,#uncontrolled-tab-example-tab-contact,#uncontrolled-tab-example-tab-interview{
	background-color:#ffffff00 !important;
}

#uncontrolled-tab-example{
	padding:5px 10px;
	justify-content:space-around;
	background-color:#faf6f3;
	font-weight:bold;

}

.box:hover{
	box-shadow: 0 0 11px rgba(255,165,0,0.7)!important
}
.nav-link.active{
	color: #ff7200;
	padding:15px 40px!important;
	background-color: #ffe4d3 !important;
	color:#ff7200 !important;
}
.v1{
	padding:10px 90px!important;
	
}
.nav-tabs{
	border:none!important;
}

.box1:hover h6, .box1:hover i {
	color: orange;
}
.box1{
	border-radius:14px !important;
}
.box1:hover{
	box-shadow: 0 0 20px #ccc !important;
}
.img1{
	width:60px;
}
.v1{
	background-color:#ffe4d3!important;
}
.section h2{
	font-family:Droid Serif;
	font-weight:700;
	font-size:30px;
	line-height:36px
}
.select-div:hover .theme-a{
	color:rgb(255, 114, 0)!important;
}
.select-div:hover, .list-alter:nth-child(odd):hover{
	background: #fff4e8 !important;
    border-color: #fbe2c7 !important;
}
.wsize{
	font-weight:400;
	font-size:14px!important;
	line-height:26px;
	color:rgb(156, 156, 156);
}
.question_tag{
	color:#1f659b!important;
	font-size:12px!important;
}

.question_tag:hover {
    color: #ff7200 !important;
}
.none{
	background:white!important;
	border-radius:none!important
}

.date{
	color:#7bb19b !important;
	font-size:12px!important;
}
.theme-a:hover{
       color:rgb(255, 114, 0)!important;
}
.top-pans{
	background:#faf6f300!important;
	padding:10px 0px !important;
}

.recent{
	background:linear-gradient(45deg, #7fe1d3, #f1ffe2);
	border-radius:10px;
}
.caption{
	background:linear-gradient(45deg, #ffa95d, #f1ffe2)
}
.text{
	color: #a5a5a5;
	font-weigth:100px!important;
	font-size:16px!important;
	border-radius:8px;
}
/* forum icon */
.sbtn{
	padding:9px 10px !important;
	border-radius:5px !important;
	font-weight:400px !important;
}
.sbtn:hover{
	background-color:black;
	color:white;
}
.imheight{
	height:50px !important;
}
.iconsfont{
	color:rgb(169, 169, 169)!important;
	font-size:14px!important;
	font-weight:400!important;
	line-height:28px!important;
}
.inheight{
	display:inline-block!important;
	height:50px !important;
}.latestcon{
	color:rgb(128, 128, 128)!important;
	font-size:12px!important;
	font-weight:400px!important;
	line-height:28px!important;
	margin-bottom:0px!important;
	
}
.cardheader{
	color:rgb(88, 88, 88)!important;
	font-size:16px!important;
	font-weight:700px!important;
	line-height:22px!important;
}
@media screen and (min-width:768px){
	.md-q{
		padding:30px 40px;
	}
}

@media screen and (min-width:250px){
	.md-q{
		padding: 15px;
	}
}
