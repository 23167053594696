@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/css/custom.css";
@import "./assets/css/style.css";
@import "./assets/css/responsiveDesign.css";

body {
  font-family: 'Droid Serif';
}
 
