/* Styles for the tab container */
.exam-tab-container {
  display: flex;
  justify-content: space-between;
  background-color: #faf4e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
  }
  
  /* Styles for each tab */
  .tab {
    flex: 1;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  color: #a9a9a9; /* Inactive tab text color */
  font-weight: bold;
  background-color: #faf4e0; /* Inactive tab background */
  transition: background-color 0.3s, color 0.3s;
  border-right: 1px solid #e0e0e0; /* Border on the right side */
  }
  
  .tab:last-child {
    border-right: none;
  }

  /* Active tab styles */
  .tab.active {
  color: white; /* Active tab text color */
  background-color: #ff7800; /* Active tab background */
}
  
  /* Tab content area */
  .tab-content {
    /* padding: 20px; */
    border-top: none;
  }

  /* Container styling */
.stats-container {
    display: flex;
    justify-content: space-evenly;
    background-color: #faf4e0;
    padding: 15px 0;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  /* Individual stat item styling */
  .stat-item {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #7a7a7a; /* Title color */
  }
  
  /* Title styling */
  .stat-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Value styling */
  .stat-value {
    font-size: 20px;
    color: #333;
    font-weight: bold;
  }
  
  